import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default (props: any) => {
  const [value, setValue] = React.useState(props.blodgroup || "unknown");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    props.onChange(event);
  };

  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend">Blood Group</FormLabel>
        <RadioGroup
          style={{ display: "block" }}
          aria-label="blodgroup"
          name="blodgroup"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel value="A+" control={<Radio />} label="A+" />
          <FormControlLabel value="A-" control={<Radio />} label="A-" />
          <FormControlLabel value="B+" control={<Radio />} label="B+" />
          <FormControlLabel value="B-" control={<Radio />} label="B-" />
          <FormControlLabel value="O+" control={<Radio />} label="O+" />
          <FormControlLabel value="O-" control={<Radio />} label="O-" />
          <FormControlLabel value="AB+" control={<Radio />} label="AB+" />
          <FormControlLabel value="AB-" control={<Radio />} label="AB-" />
          <FormControlLabel
            value="unknown"
            control={<Radio />}
            label="Unknown"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
