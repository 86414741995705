import React, {createContext, useContext, useReducer} from 'react';

export const StateContext = createContext({});

export const StateProvider = (props:any) => {
  const { initialState, reducer } = props;
  return (
    <StateContext.Provider value={useReducer(reducer,initialState)}>
    {props.children}
  </StateContext.Provider>
);}

export const useStateValue:any = () => useContext(StateContext);