import { Divider, Typography } from "@material-ui/core";
import React, { useState } from "react";

import "../mystyle.css";
import TeamCard from "../UI/TeamCard";
function Sponsor() {
  return (
    <React.Fragment>
      <h3 className="centerItem">
        Thanks to our partners, without them we would have not really made such
        a bigger impact. We are extremely-extremely thankful to every single
        individual and corporate partner.
      </h3>
      <h3 className="myheading centerItem">Individual</h3>
      <Divider />
      <br />
      <div className="teamcontainer">
        <TeamCard name={"Manish Sharma"} image={"manish.png"} />
        <TeamCard
          name={"Srihari Venkatesan"}
          image={"srihari.png"}
          message={"(Dedicated to his son, Trenayy.)"}
        />
        <TeamCard name={"Urvish Vanzara"} image={"urvish.png"} />
      </div>
      <br />
      {/* <h3 className="myheading centerItem">Corporate</h3>
      <Divider />
      <br />
      <div className="teamcontainer"></div> */}
      <br />
    </React.Fragment>
  );
}

export default Sponsor;
