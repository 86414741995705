//import  {getUserInfo,getcurrentUser,currentUser} from '../DB/api';
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const LOGIN_WITH_GOOGLE = "LOGIN_WITH_GOOGLE";

type Actions =
  | {
      type: "LOGIN";
      payload: any;
    }
  | {
      type: "SUCCESS";
      payload: any;
    }
  | {
      type: "ERROR";
    }
  | {
      type: "LOGIN_WITH_GOOGLE";
      payload: any;
    }
  | {
      type: "LOGOUT";
    };
interface loginInitialStateProps {
  name: string;
  phone: string;
  isLoading: boolean;
  error: string;
  isLoggedIn: boolean;
  uid: string;
}
type State = loginInitialStateProps;

export const LoginReducer = (state: State, action: Actions) => {
  switch (action.type) {
    case SUCCESS: {
      const user = initialStateLogin; //getUserInfo( currentUser || getcurrentUser() );
      return {
        ...state,
        uid: action.payload?.user?.uid,
        name: action.payload?.user?.email?.split("@")[0],
        email: action.payload?.user?.email,
        isLoggedIn: true,
        phone: action.payload?.user?.phoneNumber,
      };
    }

    case LOGOUT: {
      return {
        name: "",
        phone: "",
        isLoading: false,
        error: "",
        isLoggedIn: false,
        uid: "",
      };
    }
    default:
      return state;
  }
};
export const initialStateLogin: loginInitialStateProps = {
  name: "",
  phone: "",
  isLoading: false,
  error: "",
  isLoggedIn: false,
  uid: "",
};
