import React, { useState, useEffect, Suspense, lazy } from "react";
import Header from "./Component/Header/Header";

import Routes from "./Route/Routes";
import AppContainer from "./Component/Pages/AppContainer";
import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import { useStateValue } from "./StateProvider/StateProvider";
import * as api from "./DB/api";
import { SUCCESS, LOGOUT } from "./Reducer/LoginReducer";
import { Typography } from "@material-ui/core";
import Loading from "./Loading";
import "./Component/mystyle.css";
const Footer = lazy(() => import("./Component/Footer/Footer"));

const App = () => {
  const [state, dispatchState] = useStateValue();
  let Customtheme: Theme = createMuiTheme({
    palette: {
      type: state.themeReducerState.isDarkMode ? "dark" : "light",
      primary: {
        main: "#9fb63a",
      },
      secondary: {
        main: "#9fb63a",
      },
    },
    overrides: {
      MuiListItemText: {
        root: {},
      },
    },
  });
  Customtheme = responsiveFontSizes(Customtheme);
  Customtheme["overrides"]["MuiListItemText"]["root"] = {
    color: Customtheme.palette.text.primary,
  };
  const [authState, setAuthState] = useState("loaded");

  const Fbauth = async () => {
    api.firebase.auth().onAuthStateChanged(function (user: any) {
      if (user) {
        setAuthState("loaded");
        dispatchState({ type: SUCCESS, payload: { user } });
      }
    });
  };
  useEffect(() => {
    Fbauth();
  }, []);

  return (
    <React.Fragment>
      <MuiThemeProvider theme={Customtheme}>
        <Header />

        {authState === "loaded" ? (
          <React.Fragment>
            <AppContainer>
              <Routes />
              <Suspense fallback="">
                <Footer />
              </Suspense>
            </AppContainer>
          </React.Fragment>
        ) : (
          <Loading />
        )}
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default App;
