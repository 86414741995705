import firebase, { database } from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";

let config;

if (document.domain == "localhost") {
  config = {
    apiKey: "AIzaSyBCdwHrENRF57op_kd-9hU6U5rTRFRsSmo",
    authDomain: "dev-helpzindagi.firebaseapp.com",
    projectId: "dev-helpzindagi",
    storageBucket: "dev-helpzindagi.appspot.com",
    messagingSenderId: "83749075935",
    appId: "1:83749075935:web:09f41966dbc7ab7599e26a",
    measurementId: "G-T64Q9QKCMQ",
  };
} else {
  config = {
    apiKey: "AIzaSyCgfdIM6-GurwFfpjsusA90eTHir73E0wg",
    authDomain: "helpzindagi-2604f.firebaseapp.com",
    projectId: "helpzindagi-2604f",
    storageBucket: "helpzindagi-2604f.appspot.com",
    messagingSenderId: "1058702467301",
    appId: "1:1058702467301:web:4ac21f24f051feb12cb40c",
    measurementId: "G-LLD8HGC7V7",
  };
}

// config = {
//   apiKey: "AIzaSyBCdwHrENRF57op_kd-9hU6U5rTRFRsSmo",
//   authDomain: "dev-helpzindagi.firebaseapp.com",
//   projectId: "dev-helpzindagi",
//   storageBucket: "dev-helpzindagi.appspot.com",
//   messagingSenderId: "83749075935",
//   appId: "1:83749075935:web:09f41966dbc7ab7599e26a",
//   measurementId: "G-T64Q9QKCMQ",
// };

export const collection = {
  USERS: "users",
};

const firebaseObj = firebase.initializeApp(config);
const db = firebaseObj.firestore();
const batch = db.batch();

export const getServerTime = () => {
  return firebase.firestore.FieldValue.serverTimestamp();
};
const getDBData = async (dbname: string, docId: string) => {
  const ref = await db.collection(dbname).doc(docId).get();
  const data = ref.data();
  if (data) {
    return { ...data, uid: docId };
  } else {
    return { uid: false };
  }
};

const getAllDBData = async (dbname: string) => {
  const data: any = [];
  const querySnapshot = await db.collection(dbname).get();
  querySnapshot.forEach((doc) => {
    data.push({
      id: doc.id,
      ...doc.data(),
      date: new Date(doc.data().date.seconds * 1000)
        .toLocaleString()
        .split(",")[0],
    });
  });
  return data;
};

export async function saveCommentData(key: any, value: any) {
  const nycRef = db.collection("donor").doc(key);
  batch.set(nycRef, { ...value }, { merge: true });

  async function batchcommit() {
    await batch.commit().catch((e) => {
      return {
        message: e,
        status: 402,
      };
    });
    return {
      message: "added successfully",
      status: 200,
    };
  }
  return batchcommit();
}

async function saveValue(obj: any, tableName: string) {
  if (!obj) {
    return {
      status: false,
      message: "Invalid Data",
      error: true,
    };
  }
  const ref = db.collection(tableName).doc();
  const result = await ref.set({
    ...obj,
    status: "pending",
    date: getServerTime(),
  });
  return {
    ref: ref,
    status: true,
    message:
      "Thanks for coming forward and help in this noble cause to save others life.",
  };
}
export async function cancelOPDAppointment(obj: any) {
  const result = await db.collection("opdappointment").doc(obj.id).set(
    {
      status: obj.status,
    },
    { merge: true }
  );
  return {
    status: true,
    message: "",
  };
}
async function saveEditedDonorValue(
  obj: any,
  tableName: string,
  tableData: any
) {
  if (!obj) {
    return {
      status: false,
      message: "Invalid Data",
      error: true,
    };
  }
  const result = await db
    .collection(tableName)
    .doc(tableData.id)
    .set({
      ...obj,
      date: getServerTime(),
    });
  return {
    status: true,
    message:
      "Thanks for coming forward and help in this noble cause to save others life.",
  };
}
export async function saveEditedPlasmaDonor(obj: any, tableid: any) {
  obj.donorType = "plasma";
  return await saveEditedDonorValue(obj, "donor", tableid);
}

async function saveEditedPatientValue(
  obj: any,
  tableName: string,
  tabledata: any
) {
  if (!obj) {
    return {
      status: false,
      message: "Invalid Data",
      error: true,
    };
  }
  const result = await db
    .collection(tableName)
    .doc(tabledata.id)
    .set({
      ...obj,
      date: getServerTime(),
    });
  return {
    status: true,
    message:
      "Thanks for coming forward and help in this noble cause to save others life.",
  };
}
export async function saveEditedPatient(obj: any, tableid: any) {
  obj.donorType = "plasma";
  return await saveEditedPatientValue(obj, "patient", tableid);
}

export async function savePatientDailyStatus(obj: any, tableid: any) {
  if (!obj) {
    return {
      status: false,
      message: "Invalid Data",
      error: true,
    };
  }
  const result = await db
    .collection("patientdailystatus")
    .doc()
    .set({
      ...obj,
      date: getServerTime(),
    });

  await db.collection("isolation").doc(obj.id).set(
    {
      status: obj.status,
      lastmodified: getServerTime(),
    },
    { merge: true }
  );

  return {
    status: true,
    message:
      "Thanks for coming forward and help in this noble cause to save others life.",
  };
}
export async function savePlasmaDonor(obj: any) {
  obj.donorType = "plasma";
  return await saveValue(obj, "donor");
}
export async function verifyMobile(obj: any) {
  await db.collection("isolation").doc(obj.id).set(
    {
      otpVerified: true,
      mobile: obj.mobile,
    },
    { merge: true }
  );
}
export async function savePatientAdmission(obj: any) {
  const result = await saveValue(obj, "isolation");
  return {
    ref: result.ref,
    error: false,
    status: true,
    message: `We understand that you are really worried about your patient. But don’t worry everything will be fine. 

              We have received your requirement & team@helpzindagi will contact you as soon as we have the requested resources available with us. Thanks`,
  };
}
export async function savePatient(obj: any) {
  await saveValue(obj, "patient");
  return {
    error: false,
    status: true,
    message: `We understand that you are really worried about your patient. But don’t worry everything will be fine. 

              We have received your requirement & team@helpzindagi will contact you as soon as we have the requested resources available with us. Thanks`,
  };
}
export async function saveOPDPatient(obj: any) {
  await saveValue({ ...obj, date: getServerTime() }, "opdpatient");
  return {
    error: false,
    status: true,
    message: `We understand that you are really worried about your patient. But don’t worry everything will be fine. 

              We have received your requirement & team@helpzindagi will contact you as soon as we have the requested resources available with us. Thanks`,
  };
}
export async function saveAppointment(obj: any) {
  const ref = db.collection("opdappointment").doc();
  const counterDocId = new Date(obj.date).toLocaleDateString().split("/").join("");
  const counterRef: any = db
    .collection("globalpatientcounter")
    .doc(counterDocId);
  const increment = firebase.firestore.FieldValue.increment(1);
  const currentCoutner = await counterRef.get();
  const batch = db.batch();
  const token = currentCoutner.data()?.increment + 1 || 1;
  batch.set(counterRef, { increment }, { merge: true });
  batch.set(ref, {
    ...obj,
    token: token,
    createdAt: getServerTime(),
  });
  batch.commit();
  return {
    error: false,
    status: true,
    message: `We understand that you are really worried about your patient. But don’t worry everything will be fine. 

              We have received your requirement & team@helpzindagi will contact you as soon as we have the requested resources available with us. Thanks`,
  };
}
export async function saveQuery(obj: any) {
  return await saveValue(obj, "contact");
}
export async function saveOxygenDonor(obj: any) {
  obj.donorType = "oxygen";
  return await saveValue(obj, "donor");
}
export const signIn = async (email: any, password: any) => {
  return await firebaseObj.auth().signInWithEmailAndPassword(email, password);
};

export const getAllRecord = async (name: string) => {
  return await getAllDBData(name);
};

export const getDailyStatus = async (id: string) => {
  const data: any = [];
  const querySnapshot = await db
    .collection("patientdailystatus")
    .where("id", "==", id)
    //.where("date", ">", "")
    //.orderBy("date")
    .get();
  querySnapshot.forEach((doc) => {
    data.push({
      id: doc.id,
      ...doc.data(),
      creaatedAt: new Date(doc.data().date.seconds * 1000),
      date: new Date(doc.data().date.seconds * 1000)
        .toLocaleString()
        .split(",")[0],
    });
  });
  const sort = data.sort((a: any, b: any) => {
    const d1: any = new Date(a.creaatedAt);
    const d2: any = new Date(b.creaatedAt);
    return d1 - d2;
  });
  return sort;
};

export const getAppointmantDetialsAdmin = async () => {
  const date = new Date();
  const date1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const date2 = firebase.firestore.Timestamp.fromDate(date1);
  const data: any = [];
  const querySnapshot = await db
    .collection("opdappointment")
    //.where("status", "==", "pending")
    .where("date", ">=", date2)
    .get();
  querySnapshot.forEach((doc) => {
    data.push({
      ...doc.data(),
      creaatedAt: new Date(doc.data().date.seconds * 1000),
      date: new Date(doc.data().date.seconds * 1000)
        .toLocaleString()
        .split(",")[0],
      id: doc.id,
    });
  });
  const sort = data.sort((a: any, b: any) => {
    const d1: any = new Date(a.creaatedAt);
    const d2: any = new Date(b.creaatedAt);
    return d2 - d1;
  });
  return sort.filter((data: any) => data.status === "pending");
};
export const getAppointmantDetials = async (id: string) => {
  const data: any = [];
  const date = new Date();
  const date1 = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  ).getTime();

  const querySnapshot = await db
    .collection("opdappointment")
    //.where("date", ">=", date1)
    .where("authid", "==", id)
    .where("status", "==", "pending")
    .onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push({
          ...doc.data(),
          creaatedAt: new Date(doc.data().date.seconds * 1000),
          date: new Date(doc.data().date.seconds * 1000)
            .toLocaleString()
            .split(",")[0],
          id: doc.id,
        });
      });
    });

  const sort = data.sort((a: any, b: any) => {
    const d1: any = new Date(a.creaatedAt);
    const d2: any = new Date(b.creaatedAt);
    return d2 - d1;
  });
  return sort;
};
export const getPatientDetials = async (id: string) => {
  const data: any = [];
  const querySnapshot = await db
    .collection("opdpatient")
    .where("authid", "==", id)
    //.where("date", ">", "")
    //.orderBy("date")
    .get();
  querySnapshot.forEach((doc) => {
    data.push({
      id: doc.id,
      ...doc.data(),
      creaatedAt: new Date(doc.data().date.seconds * 1000),
      date: new Date(doc.data().date.seconds * 1000)
        .toLocaleString()
        .split(",")[0],
    });
  });
  const sort = data.sort((a: any, b: any) => {
    const d1: any = new Date(a.creaatedAt);
    const d2: any = new Date(b.creaatedAt);
    return d1 - d2;
  });
  return sort;
};
export const fetchDoctorSchedule = async () => {
  const data: any = [];
  const querySnapshot = await db.collection("appointmentschedule").get();
  querySnapshot.forEach((doc) => {
    data.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return data;
};

export const signOut: any = async () => {
  return await firebaseObj.auth().signOut();
};
export const signInPhoneNumber = async (
  phone: Number,
  recaptchaVerifier: any
) => {
  let appVerifier = recaptchaVerifier;
  return firebaseObj
    .auth()
    .signInWithPhoneNumber(`+91${phone}`, appVerifier)
    .then((confirmationResult) => confirmationResult)
    .catch((err) => err);
};
//signOut();
export { db, firebaseObj, firebase };
