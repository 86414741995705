import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import { AnyNaptrRecord } from "dns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "15% auto",
      gap: "10px",
      //placeItems:'center'
      //justifyContent:"center",
      alignItems: "center",
    },
    avator: {
      alignSelf: "flex-start",
      marginTop: "9px",
    },
  })
);

export default (props: any) => {
  const classes = useStyles();
  const { data } = props;
  console.log({ data });
  const handleClick = () => {
    props.onListClick(props.data);
  };

  return (
    <div className={classes.root}>
      <div className={classes.avator}>
        <Avatar>{data.donorname[0].toUpperCase()}</Avatar>
      </div>
      <div onClick={handleClick}>
        <ListItemText primary={`${data.donorname}, ${data.mobile}`} />
        <ListItemText secondary={` Gender :${data.gender}`} />
        {data.city && <ListItemText secondary={` ${data.city}`} />}
        {data.email && <ListItemText secondary={`${data.email}`} />}
        <ListItemText primary={`Donor Type ${data.donorType}`} />
        <ListItemText secondary={`${data.date}`} />
      </div>
    </div>
  );
};
