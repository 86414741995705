import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import SettingsIcon from "@material-ui/icons/Settings";
import MailIcon from "@material-ui/icons/Mail";
import { Link } from "react-router-dom";
import {
  SideBarSecondaryRoutes,
  SideBarPrimaryRoutes,
} from "../Route/routesList";
import AvatarCard from "../Component/List/AvatarCard";
import Theme from "../Component/Theme/Theme";
const useStyles = makeStyles({
  list: {
    width: "400px",
  },
  fullList: {
    width: "auto",
  },

  mylogo: {
    maxWidth: "164px",
    padding: "20px",
  },
  routerLink: {},
});

type Anchor = "top" | "left" | "bottom" | "right";
type SidebarProps = {
  open: boolean;
  handleDrawer: (state: boolean) => void;
};
export default function Sidebar(props: SidebarProps) {
  const classes = useStyles();

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    props.handleDrawer(open);
  };

  const list = (anchor: Anchor) => (
    <div
      style={{ width: "200px" }}
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <img className={classes.mylogo} src="logo-60x60.png" />
      <List className={classes.routerLink}>
        {SideBarPrimaryRoutes.map((page, index) => (
          <Link key={page.label} to={page.path} color="inherit">
            <ListItem button key={page.path}>
              <ListItemIcon>{page.icon}</ListItemIcon>
              <ListItemText primary={page.label} />
            </ListItem>
          </Link>
        ))}
        {SideBarSecondaryRoutes.map((page, index) => (
          <Link key={page.label} to={page.path} color="inherit">
            <ListItem button key={page.path}>
              <ListItemIcon>{page.icon}</ListItemIcon>
              <ListItemText primary={page.label} />
            </ListItem>
          </Link>
        ))}
      </List>
      {/* <Divider /> */}
    </div>
  );

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor={"left"}
        open={props.open}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {list("top")}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
