import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getAllRecord, signOut } from "../../src/DB/api";
import { useStateValue } from "../StateProvider/StateProvider";
import { SUCCESS, LOGOUT } from "../Reducer/LoginReducer";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import { saveCommentData } from "../DB/api";
import AddIcon from "@material-ui/icons/Add";

import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  DataGrid,
  getThemePaletteMode,
  GridColumns,
  GridEditCellPropsParams,
  GridEditRowsModel,
  GridRowsProp,
  GridRowParams,
} from "@material-ui/data-grid";
import {
  randomCreatedDate,
  randomEmail,
  randomTraderName,
  randomUpdatedDate,
} from "@material-ui/x-grid-data-generator";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PopupScreen from "./PopupScreen";
import PlasmaDonor from "../Forms/PlsmaDonor";
import SmallList from "../Component/UI/SmallList";
import HomeIslolationList from "../Component/UI/HomeIslolationList";
import DonorList from "../Component/UI/DonorList";
import ContactList from "../Component/UI/ContactList";
import PieChar from "../Component/UI/PieChar";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
var rowData: any = {};
var dataObj: any = {};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(props: any) {
  return {
    id: `simple-tab-${props.index}`,
    "aria-controls": `simple-tabpanel-${props.index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => {
  const isDark = getThemePaletteMode(theme.palette) === "dark";

  return {
    root: {
      "& .MuiDataGrid-cellEditing": {
        backgroundColor: "rgb(255,215,115, 0.19)",
        color: "#1a3e72",
      },
      "& .Mui-error": {
        backgroundColor: `rgb(126,10,15, ${isDark ? 0 : 0.1})`,
        color: isDark ? "#ff4343" : "#750f0f",
      },
      "& .MuiDataGrid-cell": {
        // lineHeight: "unset !important",
        //overflowWrap: "break-word",
        overflow: "scroll !important",
      },

      "& .MuiDataGrid-root1": {
        overflow: "scroll !important",
        textoverflow: "unset !important",
      },
    },
    root1: {
      "& .Mui-selected": { color: "#351e26" },
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  };
});

const isEditable = false;

const Dashboard = (props: any) => {
  const [value, setValue] = React.useState(0);
  const [state, dispatchState] = useStateValue();

  const logout = async () => {
    await signOut();
    dispatchState({ type: LOGOUT, payload: {} });
  };

  const handleChange = async (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValue(newValue);
    if (newValue == 0 && OPDAppointment.length == 0) {
      await fetchOPDAppointmentPatient();
    }
    if (newValue == 1 && patient.length == 0) {
      await fetchPatient();
    }
    if (newValue == 2 && donor.length == 0) {
      await fetchDonor();
    }
    if (newValue == 3 && contact.length == 0) {
      await fetchCotact();
    }
    if (newValue == 4 && report.length == 0) {
      //await fetchCotact();
    }
    if (newValue == 5) {
      await logout();
    }
  };
  const [editRowsModel, setEditRowsModel] = React.useState<GridEditRowsModel>(
    {}
  );
  const [isDonor, setIsDonor] = React.useState(0);
  const [isContact, setIsContact] = React.useState(0);
  const [isPatient, setIsPatient] = React.useState(0);
  const [isOPDAppointment, setIsOPDAppointment] = React.useState(0);
  const [report, setReport] = React.useState<GridRowsProp>([]);
  const [donor, setDonor] = React.useState<GridRowsProp>([]);
  const [patient, setPatient] = React.useState<GridRowsProp>([]);
  const [contact, setContact] = React.useState<GridRowsProp>([]);
  const [OPDAppointment, setOPDAppointment] = React.useState<GridRowsProp>([]);
  let patientColumns: GridColumns = [];
  let contactColumns: GridColumns = [];
  let donorColumns: GridColumns = [];
  let OPDAppointmentColumns: GridColumns = [];
  const classes = useStyles();
  const commentData = new Set();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditCellChange = React.useCallback(
    ({ id, field, props }: GridEditCellPropsParams) => {
      dataObj[id] = { [field]: props.value };
    },
    [editRowsModel]
  );

  function handleOnClickRow(row: any) {
    rowData = row.row;
  }

  const onListClick = (row: any) => {
    rowData = row;
    handleClickOpen();
  };
  const onSubmit = () => {
    [dataObj].map((editdata: any) => {
      // let key = Object.keys(editdata);
      // let value = Object.values(editdata);
      for (let key in editdata) {
        let value = editdata[key];
        saveCommentData(key, value);
      }
    });
    // saveCommentData(id, value);
  };

  const fetchDonor = async () => {
    const data = await await getAllRecord("donor");
    setDonor(data);
    setIsDonor(data.length);
  };
  const fetchPatient = async () => {
    const data = await await getAllRecord("opdpatient");
    setPatient(data);
    setIsPatient(data.length);
  };
  const fetchOPDAppointmentPatient = async () => {
    const data = await getAllRecord("opdappointment");
    setOPDAppointment(data);
    setIsOPDAppointment(data.length);
  };
  const fetchCotact = async () => {
    const data = await await getAllRecord("contact");
    setContact(data);
    setIsContact(data.length);
  };
  useEffect(() => {
    // props.fetchcampaignViewList();
    //fetchDonor();
    fetchOPDAppointmentPatient();
  }, [isDonor]);

  if (donor.length) {
    const keys = [
      "date",
      "donorname",
      "mobile",
      "email",
      "city",
      "gender",
      "blodgroup",
      "isReferral",
      "refername",
      "status",
      "message",
      "comment",
    ];
    donorColumns = keys.map((item) => {
      return {
        field: item,
        headerName: item.toUpperCase(),
        width: item === "comment" ? 350 : 150,
        editable: item === "comment" ? true : isEditable,
      };
    });
  }
  if (patient.length) {
    const keys = [
      "date",
      "name",
      "mobile",
      "email",
      "age",
      "gender",
      "city",
      "address",
      "message",
    ];
    patientColumns = keys.map((item) => {
      return {
        field: item,
        headerName: item.toUpperCase(),
        width: item === "comment" ? 350 : 150,
        editable: item === "comment" ? true : isEditable,
      };
    });
  }
  if (OPDAppointment.length) {
    const keys = ["date", "name", "mobile", "age", "status"];
    OPDAppointmentColumns = keys.map((item: any) => {
      const header = item.toUpperCase();
      return {
        field: item,
        headerName: header,
        width: 150,
        editable: isEditable,
      };
    });
  }
  if (contact.length) {
    const keys = Object.keys(contact[0]);
    contactColumns = keys.map((item) => {
      return {
        field: item,
        headerName: item.toUpperCase(),
        width: 150,
        editable: isEditable,
      };
    });
  }

  return (
    <div className={classes.root1}>
      <PopupScreen
        handleClickOpen={handleClickOpen}
        open={open}
        handleClose={handleClose}
        rowData={rowData}
        tab={value}
      />
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
        >
          <Tab label="Appointment" {...a11yProps({ index: 0 })} />
          <Tab label="Patient" {...a11yProps({ index: 1 })} />
          <Tab label="Donor" {...a11yProps({ index: 2 })} />
          <Tab label="Contact" {...a11yProps({ index: 3 })} />
          <Tab label="Report" {...a11yProps({ index: 4 })} />
          <Tab label="Logout" {...a11yProps({ index: 4 })} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <div className="desktopView" style={{ height: 500, width: "100%" }}>
          {
            <DataGrid
              className={classes.root}
              rows={OPDAppointment}
              columns={OPDAppointmentColumns}
              editRowsModel={editRowsModel}
              onRowClick={(row) => {
                handleOnClickRow(row);
                handleClickOpen();
              }}
              onEditCellChange={handleEditCellChange}
            />
          }
        </div>
        <div className="mobileView" style={{ minHeight: 500, width: "100%" }}>
          {OPDAppointment.map((item: any) => (
            <SmallList data={item} onListClick={onListClick} />
          ))}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="desktopView" style={{ height: 500, width: "100%" }}>
          {
            <DataGrid
              className={classes.root}
              rows={patient}
              columns={patientColumns}
              editRowsModel={editRowsModel}
              onRowClick={(row) => {
                handleOnClickRow(row);
                handleClickOpen();
              }}
              onEditCellChange={handleEditCellChange}
            />
          }
        </div>
        <div className="mobileView" style={{ minHeight: 500, width: "100%" }}>
          {patient.map((item) => (
            <HomeIslolationList data={item} onListClick={onListClick} />
          ))}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="desktopView" style={{ height: 500, width: "100%" }}>
          {
            <DataGrid
              className={classes.root}
              rows={donor}
              onRowClick={(row) => {
                handleOnClickRow(row);
                handleClickOpen();
              }}
              columns={donorColumns}
              editRowsModel={editRowsModel}
              onEditCellChange={handleEditCellChange}
            />
          }
        </div>
        <div className="mobileView" style={{ minHeight: 500, width: "100%" }}>
          {donor.map((item) => (
            <DonorList data={item} onListClick={onListClick} />
          ))}
        </div>
        {/* <div
          style={{
            marginTop: "3%",
            width: "100%",

            position: "relative",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className="mybutton"
            fullWidth
            startIcon={<SendIcon />}
            onClick={onSubmit}
          >
            SAVE{" "}
          </Button>
        </div> */}
        {/* <div
          style={{
            marginTop: "4%",
            width: "100%",

            position: "relative",
          }}
        >
          <PopupScreen>
            <PlasmaDonor />
          </PopupScreen>
        </div> */}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="desktopView" style={{ height: 500, width: "100%" }}>
          {
            <DataGrid
              className={classes.root}
              rows={contact}
              columns={contactColumns}
              editRowsModel={editRowsModel}
              onEditCellChange={handleEditCellChange}
            />
          }
        </div>
        <div className="mobileView" style={{ minHeight: 500, width: "100%" }}>
          {contact.map((item) => (
            <ContactList data={item} />
          ))}
        </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <div style={{ height: 800, width: "100%" }}>
          <PieChar />
        </div>
      </TabPanel>
    </div>
  );
};

export default Dashboard;
