import React, { useEffect, useState } from "react";
import { Divider, Paper, Theme, Typography } from "@material-ui/core";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Skeleton from "@material-ui/lab/Skeleton";
import ReviewCaard1 from "./ReviewCard1";
import ReviewCaard from "./ReviewCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      // backgroundColor: theme.palette.background.paper,
    },
    mygridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
      width: "95vw",
    },
  })
);

export default function RecentVendorSearch() {
  const classes = useStyles();
  const [vendors, setVendors] = useState([]);

  //   const fetchVendor = async () => {
  //     const data = await getAllVendor();
  //     setVendors(data);
  //   };

  //   useEffect(() => {
  //     fetchVendor();
  //   }, []);

  return (
    <React.Fragment>
      <h3 className="myheading">Reviews</h3>
      <Divider />
      <br />
      <div className="reviewContainer">
        <ReviewCaard1
          date="May 21, 2021"
          title={"Chandra"}
          city={"Bangalore"}
          content={`Thank you for coming forward and  getting in touch during our difficult times... I got discharged yesterday...I'm still on the concentrator at home... Please continue to pray for me as my lungs will take some time to recover... Thank you once again.
`}
        />
        <ReviewCaard1
          date="May 16, 2021"
          title={"Sudha Ji"}
          city={"Bangalore"}
          content={`Hello, by God's grace my husband is fine and we have come for discharge now. Thank you very much for your help🙏🏻🙏🏻🙏🏻

`}
        />
        <ReviewCaard1
          date="May 13, 2021"
          title={"Bhavna Oberoi"}
          city={"Delhi"}
          content={`Thank you Vishwa, Aarti and entire Helpzindagi team, we salute your spirit, ur kindness your humanity🙏World is a better place coz there are people like you
`}
        />
        <ReviewCaard1
          date="May 12, 2021"
          title={"Sneha"}
          city={"Bangalore"}
          content={`I reached out to Help Zindagi organization for helping me find a plasma donor for my relative who was in very critical condition.
In these unprecedented times, they have been quick to respond and extremely helpful. They have truly come forward to help people without expecting any favor. Many thanks.
`}
        />

        <ReviewCaard1
          date="May 12, 2021"
          title={"Kanwar Mohan Oberoi"}
          city={"Greater Kailash II, Delhi"}
          content={`Thanks.. for helping me out.. my father was in ICU fighting with Covid 19 for two weeks.. We were badly struggling for a plasma donor which you sorted out.Absolutely , u are life saviour🙏.

`}
        />
      </div>
    </React.Fragment>
  );
}
