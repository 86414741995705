//import  {getUserInfo,getcurrentUser,currentUser} from '../DB/api';
export const SETTHEME = "SETTHEME";

type Actions = {
  type: "SETTHEME";
  payload: any;
};

interface themeInitialStateProps {
  isDarkMode: boolean;
}
type State = themeInitialStateProps;

export const ThemeReducer = (state: State, action: Actions) => {
  switch (action.type) {
    case SETTHEME: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
export const initialStateTheme: themeInitialStateProps = {
  isDarkMode: false,
};
