import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import "../mystyle.css";
import Review from "../UI/Review";
import News from "../UI/News";
import Testimonial from "../UI/Testimonial";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));
function About() {
  const classes = useStyles();
  return (
    <React.Fragment>
      {/* <div className="myhead">
        <h2 className="mytitle"> About Us</h2>
        <hr className="texttitle"/>
     </div> */}
      <Container>
        <div className="sam">
          {/* <h3 className="myheading land">About Us</h3> */}

          <Grid container spacing={1}>
            <Grid item md={5}>
              <img className="about" src="aboutuslogo.png" />
            </Grid>
            <Grid item md={7}>
              <p className="mytext">
                <br />
                <br /> <br />
                <b>"HelpZindagi Foundation"</b> अप्रैल 2021 में भारत में अपनी
                चरम सीमा तक पहुंचने वाली Covid-19 की दूसरी लहर के साथ शुरू किया
                गया था.यह पूरी तरह से स्व-वित्त पोषित पहल थी जो स्व-प्रेरित टीम
                द्वारा की गई थी और जल्दी से कई अन्य स्वयंसेवक और प्रायोजक हमारे
                साथ शामिल हो गए है।
              </p>
              <p className="mytext">
                यह ज़िंदगी में मदद करने के एकमात्र उद्देश्य के साथ एक पूरी तरह
                से गैर-वाणिज्यिक / गैर-लाभकारी पहल है। हम अपने प्लेटफॉर्म के
                माध्यम से दाताओं और प्राप्तकर्ताओं के बीच किसी भी मौद्रिक लेनदेन
                को प्रोत्साहित / अनुमति नहीं देते हैं।
                <br />
                <br />
                अगर आप भी इस नेक काम के लिए हमारी मदद करना चाहते हैं, तो आप हमसे
                संपर्क कर सकते हैं। <Link href="contact">संपर्क करे:</Link>
              </p>
              <p className="mytext">
                <br />
                <Divider />
                <br />
                <br />
                <b>"HelpZindagi Foundation"</b> was started in April 2021 with
                the second wave of covid-19 approaching to its peak in India. It
                was a completely self-funded initiative by self motivated team
                of technocrats and quickly many other volunteers & partners
                joined us to help.
              </p>

              <p className="mytext">
                It’s a completely non-commercial/non-profit initiative with the
                sole intention of helping zindagi. We also do not
                encourage/allow any monetary transactions between donors and
                recipients via our platform.
                <br />
                <br />
                You can also contact us if you are interested in helping us by
                your donations or as a volunteer, for this noble cause.
                <Link href="contact">click here</Link>
                <br />
                <br />
                <br />
                <b>
                  HelpZindagi Foundation is registered under section 8 (1) of
                  the Companies Act, 2013 and is invloved in various charitable
                  activities as an NGO.
                  <br />
                  <br />
                  (Registration Number: 381559) (CIN: U85300DL2021NPL381559)
                </b>
              </p>
            </Grid>
          </Grid>
        </div>
        <br />
        <Testimonial />
        <br />
        <News/>
        <br />
        <Review />
        <br />
        <br />
      </Container>
    </React.Fragment>
  );
}

export default About;
