import Rect from "react";
import { useState, useEffect } from "react";
export interface IValues {
  [key: string]: any;
}
export interface IErrors {
  [key: string]: string;
}

const useForm = (
  callback: Function,
  validate: Function,
  validateOnChange: Function
) => {
  const [values, setValues] = useState<IValues>({});
  const [errors, setErrors] = useState<IErrors>({});
  const [isSubmitting, setIsSubmitting] = useState<Boolean>(false);
  // const [selectedDate, handleDateChange] = useState(new Date());
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
      setIsSubmitting(false);
    }
  }, [errors]);

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    let checkError = { ...errors };
    for (let k = 0; k < event.target.length; k++) {
      if (
        (event.target[k].type === "text" ||
          event.target[k].type === "date" ||
          event.target[k].type === "number") &&
        event.target[k].getAttribute("required") !== null
      ) {
        const validateError = validateOnChange({
          name: event.target[k].name,
          value: event.target[k].value,
        });
        checkError = { ...checkError, ...validateError };
      }
      setErrors(checkError);
    }

    setIsSubmitting(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.currentTarget.getAttribute("required") !== null) {
      setErrors(
        validateOnChange({ name: event.target.name, value: event.target.value })
      );
    }

    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));

    setIsSubmitting(false);
  };
  const clearValue = () => {
    setValues({});
  };

  return {
    handleChange,
    handleSubmit,
    setValues,
    clearValue,
    values,
    errors,
  };
};

export default useForm;
