import React, { useState } from "react";
import { Divider, ListItemAvatar, Typography } from "@material-ui/core";
import PatientDetailsCard from "../Component/UI/PatinetDetailsCard";
import { Button } from "@material-ui/core";
import { Chip, Dialog, DialogTitle } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";

import "../../src/Component/mystyle.css";
import Link from "@material-ui/core/Link";
import { useStateValue } from "../StateProvider/StateProvider";
import usePopup from "../Hooks/usePopup";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  db,
  firebase,
  getPatientDetials,
  getAppointmantDetialsAdmin,
  cancelOPDAppointment,
} from "../DB/api";
import { useEffect } from "react";
import PatientDetailsDoctorCard from "./PatientDetailsDoctorCard";

export default () => {
  const [appointment, setAppointment] = useState([]);
  const [patients, setPatients] = useState([]);
  const [isAddPatient, setAddPatient] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isHandleCancelAppointment, setIsHandleCancelAppointment]: any =
    useState({ isOpen: false, data: {} });

  const [state, dispatch] = useStateValue();
  const { open, setOpen, setMessage, message, setVariant, variant } =
    usePopup();

  useEffect(() => {
    if (state.loginReducerState.isLoggedIn) {
      fetchAppointmantData();
      const date = new Date();
      const date1 = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      const date2 = firebase.firestore.Timestamp.fromDate(date1);
      const data: any = [];

      db.collection("opdappointment")
        .where("date", ">=", date2)
        .onSnapshot((querySnapshot) => {
          const data: any = [];
          querySnapshot.forEach((doc) => {
            const creaatedAt = new Date(doc.data().date.seconds * 1000);
            const date = new Date(doc.data().date.seconds * 1000)
              .toLocaleString()
              .split(",")[0];
            data.push({
              ...doc.data(),
              date: date,
              creaatedAt: creaatedAt,
              id: doc.id,
            });
          });
          const sort = data.sort((a: any, b: any) => {
            const d1: any = new Date(a.creaatedAt);
            const d2: any = new Date(b.creaatedAt);
            return d1 - d2;
          });
          const filterdata = sort.filter(
            (data: any) => data.status === "pending"
          );
          setAppointment([...filterdata]);
          //console.log("Current data in CA: ", data.join(", "));
        });
    }
    setLoading(false);
  }, [state.loginReducerState.isLoggedIn]);
  const cancelAppointment = async (data: any) => {
    setIsHandleCancelAppointment({ isOpen: true, data: { ...data } });
    console.log("cancelOPDAppointment", data);
  };
  const handleCancelAppointment = async (isConfirm: boolean) => {
    if (!isConfirm) {
      setIsHandleCancelAppointment({ isOpen: false, data: {} });
      return;
    }
    const id: any = isHandleCancelAppointment.data.id;
    const status: any = isHandleCancelAppointment.data.status;
    const patients: any = await cancelOPDAppointment({
      id: id,
      status: status,
    });
    setIsHandleCancelAppointment({ isOpen: false, data: {} });
    setVariant("success");
    setMessage("Appintment Cancled Successfully");
    setOpen(true);
    fetchAppointmantData();
  };
  const fetchAppointmantData = async () => {
    const appointment = await getAppointmantDetialsAdmin();
    console.log("getAppointmantDetials", appointment);
    setAppointment([...appointment]);
  };
  return (
    <div className="browserHeight">
      <Dialog
        open={isHandleCancelAppointment.isOpen}
        onClose={() => {
          handleCancelAppointment(false);
        }}
        //PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {isHandleCancelAppointment.data.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {isHandleCancelAppointment.data.status}{" "}
            this appintment!!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              handleCancelAppointment(false);
            }}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleCancelAppointment(true);
            }}
            variant="contained"
            color="secondary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <br />
      <h3 className="myheading">Appointments</h3>
      <Divider />
      <br />
      {appointment.length == 0 && (
        <h2 className="myheading"> No More Appointments.</h2>
      )}
      {appointment.length > 0 && (
        <React.Fragment>
          <div className="reviewContainerAdmin">
            {appointment.map((item: any) => (
              <PatientDetailsDoctorCard
                id={item.id}
                name={item.name}
                mobile={item.mobile}
                age={item.age}
                date={item.date}
                buttonText="Cancel Appointmant"
                appointmentHandler={cancelAppointment}
                isDisableDate={true}
                token={item.token}
              />
            ))}
          </div>
        </React.Fragment>
      )}

      <br />

      <br />
    </div>
  );
};
