import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import { validateLogin, validateOnChange } from "./Validation/validation";
import LookingFor from "../Component/UI/LookingFor";
import Gender from "../Component/UI/Gender";
import Blooodgroup from "../Component/UI/Bloodgroup";
import "../Component/mystyle.css";
import Bloodgroup from "../Component/UI/Bloodgroup";
import useForm from "../Hooks/useForm";
import { savePatientAdmission } from "../DB/api";
import Popup from "../Component/UI/Alert/Popup";
import usePopup from "../Hooks/usePopup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AutoCompleteComponent from "../Component/UI/AutoCompleteComponent";
import MobileOTP from "../Component/UI/MobileOTP";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));
let documentId = "";
function PatientAdmission() {
  const classes = useStyles();
  const [openMobileOTP, setOpenMobileOTP] = useState(false);
  const [progress, setProgress] = useState(false);
  const [isPatinetVerified, setIsPatinetVerified] = useState(false);
  const { open, setOpen, setMessage, message, setVariant, variant } =
    usePopup();

  const onSubmit = async (a: any) => {
    const data = { ...values };
    if (!values.isolationcenter) {
      data.isolationcenter = "Radha Govind";
    }
    setProgress(true);
    const result: any = await savePatientAdmission(data);
    setMessage(result.message);
    if (result.error) {
      setVariant("error");
      setOpen(true);
      setProgress(false);
    } else {
      documentId = result.ref.id;
      // setVariant("success");
      setOpenMobileOTP(true);
      //clearValue();
    }
  };
  const { values, errors, handleChange, handleSubmit, clearValue } = useForm(
    onSubmit,
    validateLogin,
    validateOnChange
  );

  const filedValue = Object.assign(values, {
    gender: values.gender || "male",
    blodgroup: values.blodgroup || "unknown",
  });
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      event.target.value = "false";
    }
    handleChange(event);
  };
  const handleClose = (isSuccess: boolean) => {
    setIsPatinetVerified(isSuccess);
    setOpenMobileOTP(false);
    setProgress(false);
  };

  const filedError = Object.assign(errors);
  return (
    <React.Fragment>
      {/* <MobileOTP
        open={openMobileOTP}
        data={filedValue}
        documentId={documentId}
        handleClose={handleClose}
      /> */}
      <Popup
        open={open}
        message={message}
        variant={variant}
        setOpen={setOpen}
      />
      {isPatinetVerified && (
        <div
          style={{
            display: "grid",
            placeItems: "center",
            width: "100%",
            height: "20vh",
            color: "#f50057",
            fontWeight: "bold",
          }}
        >
          Patient {filedValue.patientname} has registered with us Successfully.
          We will get in touch with you in maximum 6 hours. Thanks
        </div>
      )}
      {!isPatinetVerified && (
        <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
          <div className="sam">
            <div style={{ display: "grid", textAlign: "center" }}>
              <h3 className="myheading">
                Register to get admission in isolation center
              </h3>
              <div>All Information registered here is confidential</div>
            </div>
          </div>
          <div className="formContainer">
            <form
              className={classes.root}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <TextField
                id="patientname"
                name="patientname"
                label="Patient Name"
                value={filedValue.patientname || ""}
                error={filedError.patientname ? true : false}
                helperText={filedError.patientname}
                onChange={handleChange}
                required
                fullWidth
                //  variant="filled"
                // autoComplete="given-name"
              />

              <TextField
                id="patientmobile"
                name="patientmobile"
                label="Patient Mobile"
                value={filedValue.patientmobile || ""}
                error={filedError.patientmobile ? true : false}
                helperText={filedError.patientmobile}
                onChange={handleChange}
                type="number"
                required
                fullWidth
                inputProps={{ minLength: 5, maxLength: 10 }}

                //  variant="filled"
                // autoComplete="given-name"
              />
              <Gender onChange={handleChange} />
              <TextField
                id="patientemail"
                name="patientemail"
                label="Patient Email"
                value={filedValue.patientemail || ""}
                error={filedError.patientemail ? true : false}
                helperText={filedError.patientemail}
                onChange={handleChange}
                // required
                fullWidth
                //  variant="filled"
                // autoComplete="given-name"
              />

              <TextField
                id="patientcity"
                name="patientcity"
                label="Patient City"
                value={filedValue.patientcity || ""}
                error={filedError.patientcity ? true : false}
                helperText={filedError.patientcity}
                onChange={handleChange}
                required
                fullWidth
                //  variant="filled"
                // autoComplete="given-name"
              />
              <TextField
                id="patientaddress"
                name="patientaddress"
                label="Patient Address"
                value={filedValue.patientaddress || ""}
                error={filedError.patientaddress ? true : false}
                helperText={filedError.patientaddress}
                onChange={handleChange}
                required
                fullWidth
                //  variant="filled"
                // autoComplete="given-name"
              />

              <React.Fragment>
                <Bloodgroup onChange={handleChange} />
                <TextField
                  id="patientage"
                  name="patientage"
                  label="Patient Age"
                  value={filedValue.patientage || ""}
                  error={filedError.patientage ? true : false}
                  helperText={filedError.patientage}
                  onChange={handleChange}
                  required
                  fullWidth
                  type={"number"}
                  //  variant="filled"
                  // autoComplete="given-name"
                />
              </React.Fragment>
              <TextField
                id="attendantname"
                name="attendantname"
                label="Attendant Name"
                value={filedValue.attendantname || ""}
                error={filedError.attendantname ? true : false}
                helperText={filedError.attendantname}
                onChange={handleChange}
                //required
                fullWidth
                //  variant="filled"
                // autoComplete="given-name"
              />
              <TextField
                id="attendantmobile"
                name="attendantmobile"
                label="Attendant Mobile"
                value={filedValue.attendantmobile || ""}
                error={filedError.attendantmobile ? true : false}
                helperText={filedError.attendantmobile}
                onChange={handleChange}
                // required
                fullWidth
                //  variant="filled"
                // autoComplete="given-name"
              />
              <AutoCompleteComponent
                handleChange={handleChange}
                filedError={filedError}
              />

              <TextField
                id="message"
                name="message"
                label="Message"
                value={filedValue.message || ""}
                error={filedError.message ? true : false}
                helperText={filedError.message}
                onChange={handleChange}
                //required
                fullWidth
                //  variant="filled"
                // autoComplete="given-name"
              />
              <p className="mytext" style={{ fontSize: "12px" }}>
                Please keep aadhaar copy of patient & attendant, doctor's
                prescription and recent SpO2 reading of patient handy when our
                volunteer calls you.
              </p>
              <FormControlLabel
                //labelPlacement="top"
                control={
                  <Checkbox
                    onChange={handleChangeCheckbox}
                    name="agreed"
                    value={true}
                  />
                }
                label={
                  <p
                    className="mytext"
                    style={{ fontSize: "12px", width: "95%" }}
                  >
                    As per the new guidelines issued by ICMR on 17th May, 2021,
                    recommendations/use of plasma therapy has been removed for
                    COVID treatment. We are requesting you to consult with your
                    doctor and discuss about new guidelines, before donating or
                    going under treatment of plasma therapy.
                    <br />I agree to share the provided information with the
                    volunteers of helpzindagi & other partner NGOs. The
                    information provided here is absolutely correct and I take
                    the responsibility for the same. I give my full consent here
                    to helpzindagi to use this information to provide best
                    possible help for my patient. I also confirm that
                    helpZindagi team is not liable and legally bound for any
                    lapses occurring out of this arrangement. helpZingagi does
                    not promote any monetary transaction for any kind of noble
                    cause and is strongly against it.
                  </p>
                }
              />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className="mybutton"
                disabled={
                  filedValue.agreed == "true" && progress === false
                    ? false
                    : true
                }
                startIcon={
                  progress === true ? <CircularProgress /> : <SendIcon />
                }
                //  onClick={handleSubmit}
              >
                {!progress ? "SUBMIT" : ""}
              </Button>
              <br />
              <br />
            </form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default PatientAdmission;
