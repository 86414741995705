import React, { useState, useEffect, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Countdown, { zeroPad } from "react-countdown-now";
import { validateOnChange, validateLogin } from "./Validation/validation";
import useForm from "../Hooks/useForm";
import Popup from "../Component/UI/Alert/Popup";
import usePopup from "../Hooks/usePopup";
import { Button } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import InputAdornment from "@material-ui/core/InputAdornment";
import { signInPhoneNumber, firebase, verifyMobile } from "../DB/api";

const renderer = (arg: any) => (
  <span style={{ marginLeft: "18px", marginTop: "4px" }}>
    <Typography display="inline" variant="caption">
      Resend in :{" "}
    </Typography>
    <Typography display="inline" variant="caption" style={{ color: "#f50057" }}>
      {zeroPad(arg.minutes)}:{zeroPad(arg.seconds)}s
    </Typography>
  </span>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "grid",
    gap: "1rem",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  hidden: {
    position: "absolute",
    top: "-10000px",
    left: "-10000px",
  },
  show: {
    position: "relative",
    top: "auto",
    left: "auto",
  },
  otp: {},
}));

const promise = new Promise((resolve) => {
  resolve(4);
});
const OTPForm = (props: any) => {
  const otpButton = useRef();
  const [confirmationResult, setConfirmationResult] = useState({
    confirm: function (code: any) {
      return promise;
    },
  });
  const { open, setOpen, setMessage, message, setVariant, variant } =
    usePopup();

  const [isReSendOTP, setReSendOTP] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [signInButtondisable, setSignInButtondisable] = useState(false);
  const classes = useStyles();
  const onSubmit = async (a: any) => {
    await signInWithPhoneNumber();
    //const result = await saveOxygenDonor(values);
  };
  const { values, errors, handleChange, handleSubmit, clearValue } = useForm(
    onSubmit,
    validateLogin,
    validateOnChange
  );

  const filedValue = Object.assign(values, {
    mobile: values.mobile === undefined ? props.patientmobile : values.mobile,
  });
  const filedError = Object.assign(errors);

  const handlePopupMessage = (result: any) => {
    setVariant(result.variant);
    setMessage(result.message);
    setOpen(result.isOpen);
  };

  const resetCaptcha = async (isReset: boolean) => {
    if (isReset) {
      const widget = await (window as any).recaptcha.render();
      (window as any).widgetId = widget;
    } else {
      (window as any).grecaptcha.reset((window as any).widgetId);
    }
    return (window as any).widgetId;
  };
  const innitCaptcha = async () => {
    let widget = false;
    if ((window as any).widgetId === undefined) {
      widget = true;
    }
    (window as any).recaptcha = new firebase.auth.RecaptchaVerifier(
      otpButton.current,
      {
        size: "invisible",
      }
    );
    return await resetCaptcha(widget);
  };
  const signInWithPhoneNumber = async () => {
    setSignInButtondisable(true);
    if (!isReSendOTP) {
      await innitCaptcha();
    }
    setIsOtp(true);

    const confirmationResult = await signInPhoneNumber(
      Number(filedValue.mobile),
      (window as any).recaptcha
    );
    if (!confirmationResult.confirm) {
      handlePopupMessage({
        message: confirmationResult.message,
        variant: "error",
        isOpen: true,
      });
    }
    setConfirmationResult(confirmationResult);
  };

  const verifyOTP = async () => {
    const code = filedValue.otp;
    if (!confirmationResult.confirm) {
      handlePopupMessage({
        message:
          "OTP Sending confirmation is pending Please try after some time.",
        variant: "error",
        isOpen: true,
      });
      return;
    }
    confirmationResult
      .confirm(code)
      .then(async (result: any) => {
        console.log("phone verfication is done");
        handlePopupMessage({
          message: "OTP Verification is done",
          variant: "success",
          isOpen: true,
        });

        /// await verifyMobile({id:props.documentId,mobile:filedValue.mobile});
        props.handleClose(true);
        props.handleConfirm();
      })
      .catch(function (error: any) {
        handlePopupMessage({
          message: "ERROR !! Invalid OTP",
          variant: "error",
          isOpen: true,
        });
        resetCaptcha(true);
        console.log("phone verfication is Error", error);
      });
  };
  const reSendOTP = () => {
    setIsOtp(false);
    setSignInButtondisable(false);
    setReSendOTP(true);
  };
  useEffect(() => {
    console.log("use effect Sigininn");
    //innitCaptcha();
  }, []);

  return (
    <React.Fragment>
      <Popup
        open={open}
        message={message}
        variant={variant}
        setOpen={setOpen}
      />
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className={classes.form}
      >
        <CssBaseline />

        <div className={classes.paper}>
          {/* <pre> {JSON.stringify(state, null, 2)}</pre> */}
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Mobile Verification
          </Typography>

          <div className={classes.form}>
            <TextField
              id="mobile"
              name="mobile"
              label="10 Digit Mobile Number"
              value={filedValue.mobile || ""}
              error={filedError.mobile ? true : false}
              helperText={filedError.mobile}
              onChange={handleChange}
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
            />
            {isOtp && (
              <TextField
                id="otp"
                name="otp"
                label="OTP"
                value={filedValue.otp || ""}
                error={filedError.otp ? true : false}
                helperText={filedError.otp}
                onChange={handleChange}
                required
                fullWidth
              />
            )}
            <div style={{ display: "flex" }}>
              {!isOtp && isReSendOTP && (
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={signInWithPhoneNumber}
                  //className="mybutton"
                  //fullWidth
                  startIcon={<SendIcon />}
                  disabled={signInButtondisable}
                >
                  Resend OTP
                </Button>
              )}

              <Button
                ref={otpButton}
                //id="otp-button"
                type="submit"
                color="primary"
                variant="contained"
                size="small"
                className={
                  !isOtp && !isReSendOTP ? classes.show : classes.hidden
                }
                //fullWidth
                startIcon={<SendIcon />}
                disabled={signInButtondisable}
              >
                {signInButtondisable ? "Sending OTP..." : "Send OTP"}
              </Button>

              {isOtp && (
                <Button
                  onClick={verifyOTP}
                  color="primary"
                  variant="contained"
                  startIcon={<SendIcon />}
                >
                  Verify OTP
                </Button>
              )}

              {!!isOtp && (
                <Countdown
                  date={Date.now() + 200000}
                  renderer={renderer}
                  onComplete={reSendOTP}
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default OTPForm;
