import React, { useState } from "react";
import Plasma from "./Plasma";
import "../Component/mystyle.css";
import {
  db,
  firebase,
  getPatientDetials,
  getAppointmantDetials,
  saveAppointment,
  cancelOPDAppointment,
  fetchDoctorSchedule,
} from "../DB/api";
import usePopup from "../Hooks/usePopup";
import Popup from "../Component/UI/Alert/Popup";
import PlasmaDonor from "./PlsmaDonor";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Oxygen from "./Oxygen";
import PatientOxyGen from "./PatientOxygen";
import PatientAdmission from "./PatientAdmission";
import MobileOTP from "../Component/UI/MobileOTP";
import OTPForm from "./OTPForm";
import { useEffect } from "react";
import { useStateValue } from "../StateProvider/StateProvider";
import PatientManager from "./PatientManager";
import { Chip, Dialog, DialogTitle } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import PatientRegistraationForm from "./PatientRegistraationForm";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Paper, { PaperProps } from "@material-ui/core/Paper";

let schedule: any = [];
const days: any = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
};

export default () => {
  const [appointment, setAppointment] = useState([]);
  const [patients, setPatients] = useState([]);
  const [lookingFor, setlookingFor] = useState("Register");
  const [isAddPatient, setAddPatient] = useState(false);
  const [openMobileOTP, setOpenMobileOTP] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isHandleCancelAppointment, setIsHandleCancelAppointment] = useState({
    isOpen: false,
    id: null,
  });
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  const [state, dispatch] = useStateValue();
  const { open, setOpen, setMessage, message, setVariant, variant } =
    usePopup();
  const handlelookingFor = (e: any) => {
    setlookingFor(e.target.value);
    console.log(e.target.value);
  };
  const handleClose = (isSuccess: boolean) => {
    // setIsPatinetVerified(isSuccess);
    setOpenMobileOTP(false);
    // setProgress(false);
  };
  const handleConfirm = (isSuccess: boolean) => {
    // setIsPatinetVerified(isSuccess);
    setOpenMobileOTP(false);
    // setProgress(false);
  };
  const bookAppointment = async (data: any) => {
    console.log("bookAppointment", appointment, "data", data);
    const isAlreadyBooked = appointment.filter(
      (item) => item.age == data.age && item.name == data.name
    );
    if (isAlreadyBooked.length) {
      setMessage("Appintment Already taken");
      setVariant("error");
      setOpen(true);
      return;
    }
    const date = new Date(selectedDate);
    const todayDate = new Date();
    const day = days[date.getDay()];
    const todayDay = days[todayDate.getDay()];
    const time = date.getHours() + "." + date.getMinutes();
    const appointData = schedule.filter((item: any) => item.id === day);
    if (!appointData.length) {
      setMessage(`OPD is closed at ${selectedDate.toString().split("GMT")[0]}`);
      setVariant("error");
      setOpen(true);
      return;
    }
    if (Number(appointData[0].closed) > Number(time) && todayDay === day) {
      setMessage(
        `OPD not allowed this time, valid time is  ${appointData[0].open} to ${appointData[0].closed}`
      );
      setVariant("error");
      setOpen(true);
      return;
    }

    console.log("schedulr", schedule);
    console.log("selectedDate", day, time, appointData);
    const patients: any = await saveAppointment({
      authid: state.loginReducerState.phone,
      ...data,
      status: "pending",
    });
    setMessage("Appintment booked Successfully");
    setVariant("success");
    setOpen(true);
    //fetchAppointmantData();
  };
  const cancelAppointment = async (data: any) => {
    setIsHandleCancelAppointment({ isOpen: true, id: data.id });
    console.log("cancelOPDAppointment", data);
  };
  const fetchPatientData = async () => {
    const patients = await getPatientDetials(state.loginReducerState.phone);
    console.log("patients", patients);
    if (patients.length) {
      setPatients([...patients]);
    }
  };
  const appointmantDetialsListner = async (id: string) => {
    const date = new Date();
    const date1 = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).getTime();

    db.collection("opdappointment")
      //.where("date", ">=", date1)
      .where("authid", "==", id)
      .where("status", "==", "pending")
      .onSnapshot((querySnapshot: any) => {
        const data: any = [];
        querySnapshot.forEach((doc: any) => {
          data.push({
            ...doc.data(),
            creaatedAt: doc.data().date
              ? new Date(doc.data().date.seconds * 1000)
              : "",
            date: doc.data().date
              ? new Date(doc.data().date.seconds * 1000)
                  .toLocaleString()
                  .split(",")[0]
              : "",
            id: doc.id,
          });
        });
        const sort = data.sort((a: any, b: any) => {
          const d1: any = new Date(a.creaatedAt);
          const d2: any = new Date(b.creaatedAt);
          return d1 - d2;
        });
        setAppointment([...sort]);
      });
  };
  const fetchAppointmantData = async () => {
    appointmantDetialsListner(state.loginReducerState.phone);
    // const appointment = await getAppointmantDetials(
    //   state.loginReducerState.phone
    // );
    // console.log("getAppointmantDetials", appointment);
    // setAppointment([...appointment]);
  };
  const addPatient = async () => {
    setAddPatient(true);
  };
  const closeAddPatient = async () => {
    setAddPatient(false);
    fetchPatientData();
    //fetchAppointmantData();
  };
  const fetchSchedule = async () => {
    schedule = await fetchDoctorSchedule();
  };
  useEffect(() => {
    if (state.loginReducerState.isLoggedIn) {
      fetchPatientData();
      fetchAppointmantData();
      fetchSchedule();
    }
    setOpenMobileOTP(!state.loginReducerState.isLoggedIn);
    setLoading(false);
  }, [state.loginReducerState.isLoggedIn]);
  const handleCancelAppointment = async (isConfirm: boolean) => {
    if (!isConfirm) {
      setIsHandleCancelAppointment({ isOpen: false, id: null });
      return;
    }
    const patients: any = await cancelOPDAppointment({
      id: isHandleCancelAppointment.id,
      status: "cancelByPatient",
    });
    setIsHandleCancelAppointment({ isOpen: false, id: null });
    setVariant("success");
    setMessage("Appintment Cancled Successfully");
    setOpen(true);

    //fetchAppointmantData();
  };
  return (
    <React.Fragment>
      <Popup
        open={open}
        message={message}
        variant={variant}
        setOpen={setOpen}
      />
      <Dialog
        open={isHandleCancelAppointment.isOpen}
        onClose={() => {
          handleCancelAppointment(false);
        }}
        //PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Cancel Appintment
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this appintment!!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              handleCancelAppointment(false);
            }}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleCancelAppointment(true);
            }}
            variant="contained"
            color="secondary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isAddPatient}
        onClose={closeAddPatient}
        fullWidth={true}
        maxWidth={"xl"}
        // fullScreen
      >
        <DialogActions>
          <IconButton aria-label="close" onClick={closeAddPatient}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <div style={{ minHeight: "50vh" }}>
          {/* <div className="donorContainer">
            <FormControl component="fieldset">
              <RadioGroup
                style={{ display: "block" }}
                aria-label="lookingFor"
                name="lookingFor"
                value={lookingFor}
                onChange={handlelookingFor}
              >
                <FormControlLabel
                  value="Register"
                  control={<Radio />}
                  label={<p className="mytext">Register Patient</p>}
                />
                <FormControlLabel
                  value="Admission"
                  control={<Radio />}
                  label={
                    <p className="mytext">
                      I am looking for admission in isolation center
                    </p>
                  }
                />
                <FormControlLabel
                  value="Oxygen"
                  control={<Radio />}
                  label={
                    <p className="mytext">I am Looking For Oxygen or Plasma</p>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div> */}
          {lookingFor === "Register" && (
            <PatientRegistraationForm closeAddPatient={closeAddPatient} />
          )}
          {lookingFor === "Oxygen" && <PatientOxyGen />}
          {lookingFor === "Admission" && <PatientAdmission />}
        </div>
      </Dialog>
      <div className="browserHeight">
        {!loading && openMobileOTP && (
          <OTPForm handleClose={handleClose} handleConfirm={handleConfirm} />
        )}
        {!loading && !openMobileOTP && (
          <PatientManager
            addPatient={addPatient}
            patients={patients}
            appointment={appointment}
            bookAppointment={bookAppointment}
            cancelAppointment={cancelAppointment}
            setSelectedDate={setSelectedDate}
          />
        )}
      </div>
    </React.Fragment>
  );
};
