import React from 'react';
import { Typography } from '@material-ui/core';
function Loading() {
  return (
    <Typography className={'centeredLoading'}>Loading... </Typography> 
    
  );
}

export default Loading;
