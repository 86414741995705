import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { signIn, signOut } from "../DB/api";
import Popup from "../Component/UI/Alert/Popup";
import usePopup from "../Hooks/usePopup";
import useForm from "../Hooks/useForm";
import Divider from "@material-ui/core/Divider";
import { useStateValue } from "../StateProvider/StateProvider";
import { SUCCESS, LOGOUT } from "../Reducer/LoginReducer";

import {
  validateLogin,
  validateOnChange,
} from "../Forms/Validation/validation";

import "../Component/mystyle.css";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));
function Login() {
  const [disable, setDisable] = useState(false);
  const [state, dispatchState] = useStateValue();

  const classes = useStyles();
  const {
    open,
    setOpen,
    setMessage,
    message,
    setVariant,
    variant,
  } = usePopup();

  const onSubmit = async (a: any) => {
    try {
      const result = await signIn(values.email, values.password);
      dispatchState({ type: SUCCESS, payload: { ...result } });
      setDisable(true);
    } catch (error) {
      setMessage("Invalid Credential!!!!");
      setVariant("error");
      setOpen(true);
    }

    //

    clearValue();
    setDisable(false);
  };
  const logout = async () => {
    await signOut();
    dispatchState({ type: LOGOUT, payload: {} });
  };
  const { values, errors, handleChange, handleSubmit, clearValue } = useForm(
    onSubmit,
    validateLogin,
    validateOnChange
  );

  useEffect(() => {
   // logout();
  }, []);

  let filedValue = Object.assign(values);

  const filedError = Object.assign(errors);

  return (
    <React.Fragment>
      <Popup
        open={open}
        message={message}
        variant={variant}
        setOpen={setOpen}
      />
      <div className="myhead">
        <h2 className="mytitle"> Login</h2>
        <hr className="texttitle" />
      </div>
      <br />
      <Container className="contactpage" style={{ minHeight: "100vh" }}>
        <div className="sam">
          <Grid container spacing={3}>
            <Grid item md={7}>
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  value={filedValue.email || ""}
                  error={filedError.email ? true : false}
                  helperText={filedError.email}
                  onChange={handleChange}
                  required
                  fullWidth
                  //  variant="filled"
                  // autoComplete="given-name"
                />
                <TextField
                  id="password"
                  name="password"
                  label="password"
                  value={filedValue.password || ""}
                  error={filedError.password ? true : false}
                  helperText={filedError.password}
                  onChange={handleChange}
                  required
                  fullWidth
                  type="password"
                  //  variant="filled"
                  // autoComplete="given-name"
                />

                <Button
                  variant="contained"
                  color="secondary"
                  className="mybutton"
                  type="submit"
                  disabled={disable}
                  startIcon={<SendIcon />}
                >
                  SUBMIT{" "}
                </Button>
              </form>
            </Grid>
          </Grid>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Login;
