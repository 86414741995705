//import  {getUserInfo,getcurrentUser,currentUser} from '../DB/api';
export const LANGUAGE = "LANGUAGE";

type Actions = {
  type: "LANGUAGE";
  payload: any;
};
interface languageInitialStateProps {
  language: string;
}
type State = languageInitialStateProps;

export const LanguageReducer = (state: State, action: Actions) => {
  switch (action.type) {
    case LANGUAGE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
export const initialStateLanguage: languageInitialStateProps = {
  language: "english",
};
