import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import UpdatePlasmaDonor from "../Forms/UpdatePlasmaDonor";
import UpdatePatient from "../Forms/UpdatePatient";
import PatientIsolationForm from "../Forms/PatientIsolationReportForm";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "720px",
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    marginRight: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PopupScreen(props: any) {
  const { handleClickOpen, open, handleClose, rowData, tab } = props;
  const tabs =["Isolation","Patient" , "Donor"]
  const header = tabs[tab];

  return (
    <div className="centerItem" style={{ paddingLeft: "20px" }}>
      <Dialog
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {header} Details
        </DialogTitle>
        {tab == 2 && <UpdatePlasmaDonor rowData={rowData} />}
        {tab == 1 && <UpdatePatient rowData={rowData} />}
        {tab == 0 && <PatientIsolationForm rowData={rowData} />}
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
