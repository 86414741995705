import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import useForm from "../Hooks/useForm";
import { validateLogin, validateOnChange } from "./Validation/validation";
import Gender from "../Component/UI/Gender";
import Blooodgroup from "../Component/UI/Bloodgroup";
import "../Component/mystyle.css";
import Bloodgroup from "../Component/UI/Bloodgroup";
import {
  savePlasmaDonor,
  getAllRecord,
  saveEditedPlasmaDonor,
  firebase,
} from "../DB/api";
import Popup from "../Component/UI/Alert/Popup";
import usePopup from "../Hooks/usePopup";
import { LaptopChromebook } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import Rtpcr from "../Component/UI/Rtpcr";
import DonatePlasma from "../Component/UI/DonatePlasma";
import Diseases from "../Component/UI/Diseases";
import Alcohal from "../Component/UI/Alcohal";
import "../Component/mystyle.css";

import DischargeReport from "../Component/UI/DischargeReport";
import Vaccinated from "../Component/UI/Vaccinated";
import PlasamaDonorStatus from "../Component/UI/PlasamaDonorStatus";
import { Input, MobileStepper } from "@material-ui/core";
import { useStateValue } from "../StateProvider/StateProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      nbjmwidth: "100%",
    },
  },
}));
function UpdatePlasmaDonor(props: any) {
  const [state, dispatchState] = useStateValue();
  const rowData = { ...props };
  console.log(rowData);
  const doc: any = [];
  const classes = useStyles();
  const [enableSubmit, setenableSubmit] = useState(true);
  const [images, setImages] = useState([]);
  const { open, setOpen, setMessage, message, setVariant, variant } =
    usePopup();

  const DownLoadFiles = () => {
    const mobile = filedValue.mymobile || filedValue.mobile;
    const ref = `/doc/${mobile}/${props.rowData.id}/`;
    const storageRef = firebase.storage().ref(ref);

    const ImageRef = filedValue.documents.map((item: any) =>
      storageRef.child(item).getDownloadURL()
    );

    // Get the download URL
    Promise.all(ImageRef)
      .then((url) => {
        const newImage = images;
        newImage.push(url);
        setImages([...url]);
        // Insert url into an <img> tag to "download"
      })
      .catch(() => {});
  };

  const onSubmit = async () => {
    if (values.refername) {
      values.isReferral = true;
    } else {
      values.isReferral = false;
    }
    const result = await saveEditedPlasmaDonor(
      {
        ...values,
        laastModifiedBy: state.loginReducerState.name,
      },
      props.rowData
    );
    setMessage(result.message);
    if (result.error) {
      setVariant("error");
    } else {
      setVariant("success");
    }
    clearValue();
    setOpen(true);
  };
  const { values, errors, handleChange, handleSubmit, clearValue } = useForm(
    onSubmit,
    validateLogin,
    validateOnChange
  );
  const uploadFiles = (imageAsFile: any) => {
    const name = props.rowData.id + "_" + imageAsFile.name.replaceAll(" ", "");
    const mobile = filedValue.mymobile || filedValue.mobile;

    const ref = `/doc/${mobile}/${props.rowData.id}/`;
    const storageRef = firebase.storage().ref(ref);

    const ImageRef = storageRef.child(name);
    ImageRef.put(imageAsFile).then(() => {
      filedValue.documents.push(name);
      DownLoadFiles();
      onSubmit();
    });
  };

  const [progress, setProgress] = useState(0);
  const [isUploading, setUploading] = useState(false);
  const [uploadImage, setUploadedImage] = useState([]);

  const documentsUpload = (e: any) => {
    let { files } = e.target;
    filedValue.documents = props.rowData.documents || [];
    [...files].forEach(function (item: any) {
      uploadFiles(item);
    });
  };
  const filedValue = Object.assign(values, {
    donorname: values.donorname || props.rowData.donorname,
    mobile: values.mobile || props.rowData.mobile,
    email: values.email || props.rowData.email,
    city: values.city || props.rowData.city,
    gender: values.gender || props.rowData.gender,
    blodgroup: values.blodgroup || props.rowData.blodgroup,
    rtpcr: values.rtpcr || props.rowData.rtpcr,
    covidpositive: values.covidpositive || props.rowData.covidpositive,
    covidnegative: values.covidnegative || props.rowData.covidnegative,
    message: values.message || props.rowData.message,
    comment: values.comment || props.rowData.comment || "",
    donateplasma: values.donateplasma || props.rowData.donateplasma || "no",
    diseases: values.diseases || props.rowData.diseases || "none",
    alcohal: values.alcohal || props.rowData.alcohal || "yes",
    dischargereport:
      values.dischargereport || props.rowData.dischargereport || "yes",
    vaccinated: values.vaccinated || props.rowData.vaccinated || "yes",
    refername: values.refername || props.rowData.refername || "",
    status: values.status || props.rowData.status || "",
    documents: values.documents || props.rowData.documents || [],
  });

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setAgreed(event.target.checked);
    if (!event.target.checked) {
      event.target.value = "false";
    }
    handleChange(event);
  };
  const filedError = Object.assign(errors);
  useEffect(() => {
    DownLoadFiles();
  }, []);
  return (
    <React.Fragment>
      <Popup
        open={open}
        message={message}
        variant={variant}
        setOpen={setOpen}
      />
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          id="donorname"
          name="donorname"
          label="Donor Name"
          value={filedValue.donorname || ""}
          error={filedError.donorname ? true : false}
          helperText={filedError.donorname}
          onChange={handleChange}
          required
          fullWidth
          //  variant="filled"
          // autoComplete="given-name"
        />
        <TextField
          id="mobile"
          name="mobile"
          label="Mobile"
          value={filedValue.mobile || ""}
          error={filedError.mobile ? true : false}
          helperText={filedError.mobile}
          onChange={handleChange}
          required
          fullWidth
          //  variant="filled"
          // autoComplete="given-name"
        />
        <TextField
          id="email"
          name="email"
          label="Email"
          value={filedValue.email || ""}
          error={filedError.email ? true : false}
          helperText={filedError.email}
          onChange={handleChange}
          fullWidth
          // required

          //  variant="filled"
          // autoComplete="given-name"
        />
        <TextField
          id="city"
          name="city"
          label="City"
          value={filedValue.city || ""}
          error={filedError.city ? true : false}
          helperText={filedError.city}
          onChange={handleChange}
          required
          fullWidth
          //  variant="filled"
          // autoComplete="given-name"
        />
        <TextField
          id="refername"
          name="refername"
          label="Referer Name"
          value={filedValue.refername || ""}
          error={filedError.refername ? true : false}
          helperText={filedError.refername}
          onChange={handleChange}
          fullWidth
          //  variant="filled"
          // autoComplete="given-name"
        />
        <Gender onChange={handleChange} gender={filedValue.gender} />
        <Bloodgroup onChange={handleChange} blodgroup={filedValue.blodgroup} />
        <Rtpcr onChange={handleChange} rtpcr={filedValue.rtpcr} />
        <div style={{ display: "flex" }}>
          <TextField
            id="date"
            name="covidpositive"
            label="Covid Positive Date"
            type="date"
            value={filedValue.covidpositive}
            required={filedValue.rtpcr === "yes"}
            onChange={handleChange}
            error={filedError.covidpositive ? true : false}
            helperText={filedError.covidpositive}
            // defaultValue="2021-05-01"
            // className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          &nbsp;&nbsp; &nbsp;&nbsp;
          <TextField
            id="date"
            name="covidnegative"
            label="Covid Negative Date"
            type="date"
            required={filedValue.rtpcr == "yes"}
            value={filedValue.covidnegative}
            onChange={handleChange}
            error={filedError.covidnegative ? true : false}
            helperText={filedError.covidnegative}
            // defaultValue="2021-05-01"
            // className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <Diseases
          onChange={handleChange}
          diseases={filedValue.diseases || ""}
        />
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        <DonatePlasma
          onChange={handleChange}
          donateplasma={filedValue.donateplasma || ""}
        />
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        <Alcohal
          onChange={handleChange}
          alcohal={filedValue.alcohal || ""}
          fullWidth
        />
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        <DischargeReport
          onChange={handleChange}
          dischargereport={filedValue.dischargereport || ""}
        />
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        <Vaccinated
          onChange={handleChange}
          vaccinated={filedValue.vaccinated || ""}
        />
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        <PlasamaDonorStatus
          onChange={handleChange}
          status={filedValue.status || ""}
        />
        <TextField
          id="message"
          name="message"
          label="Message"
          value={filedValue.message || ""}
          error={filedError.message ? true : false}
          helperText={filedError.message}
          onChange={handleChange}
          //required
          fullWidth
          //  variant="filled"
          // autoComplete="given-name"
        />
        <TextField
          id="comment"
          name="comment"
          label="Comment"
          value={filedValue.comment || ""}
          error={filedError.comment ? true : false}
          helperText={filedError.comment}
          onChange={handleChange}
          // required
          fullWidth
          //  variant="filled"
          // autoComplete="given-name"
        />
        <input
          color="primary"
          accept="*"
          type="file"
          onChange={documentsUpload}
          id="icon-button-file"
          style={{ display: "none" }}
        />
        <label htmlFor="icon-button-file">
          <Button
            variant="contained"
            component="span"
            size="large"
            color="primary"
            fullWidth
          >
            Upload Documents
          </Button>
        </label>
        <br />
        <br />
        <div className="imageView">
          {images.map((image) => (
            <img src={image} height="50px" width="100px"></img>
          ))}
        </div>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          className="mybutton"
          fullWidth
          startIcon={<SendIcon />}

          //  onClick={handleSubmit}
        >
          SUBMIT{" "}
        </Button>
      </form>
    </React.Fragment>
  );
}

export default UpdatePlasmaDonor;
