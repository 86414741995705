import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
const options = ["Radha Kund, Mathura (UP)"];
export default (props: any) => {
  const [value, setValue] = React.useState<string | null>(options[0]);
  const [inputValue, setInputValue] = React.useState("");

  return (
    <div>
      <Autocomplete
        disableClearable
        value={value}
        onChange={(event: any, newValue: string | null) => {
          event.target = { value: newValue, name: "isolationcenter" };
          setValue(newValue);
          props.handleChange(event);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        //style={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Isolation Center Name"
            fullWidth
            name="isolationcenter"
            // required
            //error={props.filedError.isolationcenter ? true : false}
          />
        )}
      />
    </div>
  );
};
