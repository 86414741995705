import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withRouter } from "react-router-dom";
import { HeaderTabsList } from "../Route/routesList";
import { withWidth, Paper } from "@material-ui/core";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

interface LinkTabProps {
  label?: string;
  href?: string;
  icon?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
  },
  tab: {
    justifyContent: "space-evenly",
    background: theme.palette.type === "dark" ? "#212121" : "#f5f5f5",
    display: "flex",
    maxHeight: "66px",
    width: "100%",
  },
}));

const HeaderTabs = (historyProps: any) => {
  const classes = useStyles();
  const index = HeaderTabsList.findIndex(
    (x) => x.path === historyProps.history.location.pathname
  );
  const [value, setValue] = React.useState(index === -1 ? 0 : index);
  const isIcon =
    historyProps.width === "sm" || historyProps.width === "xs" ? true : false;

  const LinkTab = (props: LinkTabProps) => {
    let style = {};
    if (
      props.label === "Donate" &&
      historyProps.width !== "sm" &&
      historyProps.width !== "xs"
    ) {
      style = {
        background: "#900000",
        color: "white",
        borderRadius: "30px",
      };
    }
    return (
      <Tab
        component="a"
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          historyProps.history.push(props.href.substr(1));
          event.preventDefault();
        }}
        {...props}
        icon={isIcon ? props.icon : ""}
        style={style}
      />
    );
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="nav tabs example"
        className={classes.tab}
      >
        {HeaderTabsList.map((tab, index) => {
          if (index <= 3 && historyProps.width === "xs") {
            return (
              <LinkTab
                key={tab.label}
                label={
                  historyProps.width === "xs"
                    ? tab.label.split(" ")[0]
                    : tab.label
                }
                href={tab.path}
                icon={tab.icon}
                {...a11yProps(index)}
              />
            );
          } else if (historyProps.width !== "xs") {
            return (
              <LinkTab
                key={tab.label}
                label={
                  historyProps.width === "xs"
                    ? tab.label.split(" ")[0]
                    : tab.label
                }
                href={tab.path}
                icon={tab.icon}
                {...a11yProps(index)}
              />
            );
          }
        })}
      </Tabs>
    </React.Fragment>
  );
};
export default withWidth()(withRouter(HeaderTabs));
