import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default (props: any) => {
  const [value, setValue] = React.useState(props.donateplasma || "no");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    props.onChange(event);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        Did you ever Donate plasma before ?
      </FormLabel>
      <RadioGroup
        style={{ display: "block" }}
        aria-label="Donateplasma"
        name="donateplasma"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};
