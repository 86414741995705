import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import { validateLogin, validateOnChange } from "./Validation/validation";
import LookingFor from "../Component/UI/LookingFor";
import Gender from "../Component/UI/Gender";
import Blooodgroup from "../Component/UI/Bloodgroup";
import "../Component/mystyle.css";
import Bloodgroup from "../Component/UI/Bloodgroup";
import useForm from "../Hooks/useForm";
import { saveOPDPatient } from "../DB/api";
import Popup from "../Component/UI/Alert/Popup";
import usePopup from "../Hooks/usePopup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useStateValue } from "../StateProvider/StateProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));
function PatientRegistraationForm(props: any) {
  const classes = useStyles();
  const [state] = useStateValue();
  const { open, setOpen, setMessage, message, setVariant, variant } =
    usePopup();

  const onSubmit = async (a: any) => {
    const data = { ...values, authid: state.loginReducerState.phone };
    const result = await saveOPDPatient(data);

    setMessage(result.message);
    if (result.error) {
      setVariant("error");
    } else {
        setVariant("success");
        setTimeout(()=>props.closeAddPatient(),1000)
      
    }
    clearValue();
    setOpen(true);
  };
  const { values, errors, handleChange, handleSubmit, clearValue } = useForm(
    onSubmit,
    validateLogin,
    validateOnChange
  );

  const filedValue = Object.assign(values, {
    gender: values.gender || "male",
    
  });
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      event.target.value = "false";
    }
    handleChange(event);
  };

  const filedError = Object.assign(errors);

  return (
    <React.Fragment>
      <Popup
        open={open}
        message={message}
        variant={variant}
        setOpen={setOpen}
      />
      <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
        <div className="sam">
          <div style={{ display: "grid", textAlign: "center" }}>
            <h3 className="myheading">Patient Registeration</h3>
            <div>All Information registered here is confidential</div>
          </div>
        </div>
        <div className="formContainer">
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              id="name"
              name="name"
              label="Name"
              value={filedValue.name || ""}
              error={filedError.name ? true : false}
              helperText={filedError.name}
              onChange={handleChange}
              required
              fullWidth
              //  variant="filled"
              // autoComplete="given-name"
            />
            <TextField
              id="mobile"
              name="mobile"
              label="Mobile"
              value={filedValue.mobile || ""}
              error={filedError.mobile ? true : false}
              helperText={filedError.mobile}
              onChange={handleChange}
              required
              fullWidth
              //  variant="filled"
              // autoComplete="given-name"
            />
            <TextField
              id="email"
              name="email"
              label="Email"
              value={filedValue.email || ""}
              error={filedError.email ? true : false}
              helperText={filedError.email}
              onChange={handleChange}
              // required
              fullWidth
              //  variant="filled"
              // autoComplete="given-name"
            />

            <TextField
              id="city"
              name="city"
              label="City"
              value={filedValue.city || ""}
              error={filedError.city ? true : false}
              helperText={filedError.city}
              onChange={handleChange}
              required
              fullWidth
              //  variant="filled"
              // autoComplete="given-name"
            />
            <TextField
              id="address"
              name="address"
              label="Address"
              value={filedValue.address || ""}
              error={filedError.address ? true : false}
              helperText={filedError.address}
              onChange={handleChange}
              required
              fullWidth
              //  variant="filled"
              // autoComplete="given-name"
            />

            <Gender onChange={handleChange} />

            <React.Fragment>
              <TextField
                id="age"
                name="age"
                label="Age"
                value={filedValue.age || ""}
                error={filedError.age ? true : false}
                helperText={filedError.age}
                onChange={handleChange}
                required
                fullWidth
                type={"number"}
                //  variant="filled"
                // autoComplete="given-name"
              />
            </React.Fragment>

            <TextField
              id="message"
              name="message"
              label="Message"
              value={filedValue.message || ""}
              error={filedError.message ? true : false}
              helperText={filedError.message}
              onChange={handleChange}
              //required
              fullWidth
              //  variant="filled"
              // autoComplete="given-name"
            />

            <FormControlLabel
              //labelPlacement="top"
              control={
                <Checkbox
                  onChange={handleChangeCheckbox}
                  name="agreed"
                  value={true}
                />
              }
              label={
                <p
                  className="mytext"
                  style={{ fontSize: "12px", width: "95%" }}
                >
                  As per the new guidelines issued by ICMR on 17th May, 2021,
                  recommendations/use of plasma therapy has been removed for
                  COVID treatment. We are requesting you to consult with your
                  doctor and discuss about new guidelines, before donating or
                  going under treatment of plasma therapy.
                  <br />I agree to share the provided information with the
                  volunteers of helpzindagi & other partner NGOs. The
                  information provided here is absolutely correct and I take the
                  responsibility for the same. I give my full consent here to
                  helpzindagi to use this information to provide best possible
                  help for my patient. I also confirm that helpZindagi team is
                  not liable and legally bound for any lapses occurring out of
                  this arrangement. helpZingagi does not promote any monetary
                  transaction for any kind of noble cause and is strongly
                  against it.
                </p>
              }
            />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className="mybutton"
              disabled={filedValue.agreed == "true" ? false : true}
              startIcon={<SendIcon />}
              size="small"
              //  onClick={handleSubmit}
            >
              SUBMIT{" "}
            </Button>

            <br />
            <br />
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PatientRegistraationForm;
