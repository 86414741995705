import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import useForm from "../Hooks/useForm";
import { validateLogin, validateOnChange } from "./Validation/validation";
import Gender from "../Component/UI/Gender";
import Blooodgroup from "../Component/UI/Bloodgroup";
import "../Component/mystyle.css";
import Bloodgroup from "../Component/UI/Bloodgroup";
import { saveOxygenDonor } from "../DB/api";
import Popup from "../Component/UI/Alert/Popup";
import usePopup from "../Hooks/usePopup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));
function DonationForm() {
  const classes = useStyles();
  const { open, setOpen, setMessage, message, setVariant, variant } =
    usePopup();
  const onSubmit = async (a: any) => {
    openCheckout();
    // const result = await saveOxygenDonor(values);

    // setMessage(result.message);
    // if (result.error) {
    //   setVariant("error");
    // } else {
    //   setVariant("success");
    // }
    // clearValue();
    // setOpen(true);
  };
  const { values, errors, handleChange, handleSubmit, clearValue } = useForm(
    onSubmit,
    validateLogin,
    validateOnChange
  );

  const filedValue = Object.assign(values, {
    gender: "male",
    bloodgroup: "A+",
  });
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      event.target.value = "false";
    }
    handleChange(event);
  };
  const filedError = Object.assign(errors);
  const openCheckout = () => {
    let options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: filedValue.amount * 100, // 2000 paise = INR 20, amount in paisa
      name: "HelpZindagi",
      reference_id: new Date().getTime(),
      description: "Donation for...",
      image: "logo-60x60.png",
      currency: "INR",
      handler: function (response: any) {
        alert(response.razorpay_payment_id);
      },
      customer: {
        name: filedValue.name,
        email: filedValue.email,
        contact: filedValue.mobile,
      },
      prefill: {
        name: filedValue.name,
        email: filedValue.email,
        contact: filedValue.mobile,
      },
      notes: {
        address: filedValue.address,
      },
      theme: {
        color: "#F37254",
      },
    };

    let rzp = (window as any).Razorpay(options);
    rzp.open();
  };

  return (
    <React.Fragment>
      <Popup
        open={open}
        message={message}
        variant={variant}
        setOpen={setOpen}
      />
      <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
        <div className="sam">
          <div style={{ display: "grid", textAlign: "center" }}>
            <h3 className="myheading">Donation</h3>
            <div>All Information registered here is confidential</div>
          </div>
        </div>
        <div className="formContainer">
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              id="amount"
              name="amount"
              label="Amount"
              value={filedValue.amount || ""}
              error={filedError.amount ? true : false}
              helperText={filedError.amount}
              onChange={handleChange}
              required
              fullWidth
              type={"number"}
              //  variant="filled"
              // autoComplete="given-name"
            />
            <TextField
              id="name"
              name="name"
              label="Name"
              value={filedValue.name || ""}
              error={filedError.name ? true : false}
              helperText={filedError.name}
              onChange={handleChange}
              required
              fullWidth
              //  variant="filled"
              // autoComplete="given-name"
            />
            <TextField
              id="mobile"
              name="mobile"
              label="Mobile"
              value={filedValue.mobile || ""}
              error={filedError.mobile ? true : false}
              helperText={filedError.mobile}
              onChange={handleChange}
              required
              fullWidth
              //  variant="filled"
              // autoComplete="given-name"
            />
            <TextField
              id="email"
              name="email"
              label="Email"
              value={filedValue.email || ""}
              error={filedError.email ? true : false}
              helperText={filedError.email}
              onChange={handleChange}
              // required
              fullWidth
              //  variant="filled"
              // autoComplete="given-name"
            />

            <TextField
              id="city"
              name="city"
              label="City"
              value={filedValue.city || ""}
              error={filedError.city ? true : false}
              helperText={filedError.city}
              onChange={handleChange}
              // required
              fullWidth
              //  variant="filled"
              // autoComplete="given-name"
            />
            <TextField
              id="address"
              name="address"
              label="Address"
              value={filedValue.address || ""}
              error={filedError.address ? true : false}
              helperText={filedError.address}
              onChange={handleChange}
              //required
              fullWidth
              //  variant="filled"
              // autoComplete="given-name"
            />

            <TextField
              id="pancard"
              name="pancard"
              label="Pancard"
              value={filedValue.pancard || ""}
              error={filedError.pancard ? true : false}
              helperText={filedError.pancard}
              onChange={handleChange}
              required
              fullWidth

              //  variant="filled"
              // autoComplete="given-name"
            />

            <FormControlLabel
              //labelPlacement="top"
              control={
                <Checkbox
                  onChange={handleChangeCheckbox}
                  name="agreed"
                  value={true}
                />
              }
              label={
                <p className="mytext" style={{ fontSize: "12px" }}>
                  I confirm that I am citizen of India.
                </p>
              }
            />

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className="mybutton"
              disabled={filedValue.agreed == "true" ? false : true}
              startIcon={<SendIcon />}
              //  onClick={handleSubmit}
            >
              Pay Rs. {filedValue.amount || ""}
            </Button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DonationForm;
