import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default (props: any) => {
  const [value, setValue] = React.useState(props.status || "");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    props.onChange(event);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Status</FormLabel>
      <RadioGroup
        style={{ display: "block" }}
        aria-label="Status"
        name="status"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="pending" control={<Radio />} label="Pending" />
        <FormControlLabel value="admit" control={<Radio />} label="admit" />
        <FormControlLabel
          value="discharge"
          control={<Radio />}
          label="discharge"
        />
      </RadioGroup>
    </FormControl>
  );
};
