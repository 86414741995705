import React, { lazy } from "react";
import InfoIcon from "@material-ui/icons/Info";
import HomeIcon from "@material-ui/icons/Home";
import PolicyIcon from "@material-ui/icons/Policy";
import ContactsIcon from "@material-ui/icons/Contacts";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import SpeedIcon from "@material-ui/icons/Speed";
import Home from "../Component/Pages/Home";
import TimelineIcon from "@material-ui/icons/Timeline";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
//const Home =lazy(() => import( '../Component/Pages/Home'));
import Sponsor from "../Component/Pages/Sponsor";
import DoctorAdmin from "../../src/Admin/DoctorAdmin";
import Admin from "../../src/Admin/Admin";
import About from "../Component/Pages/About";
import Oxygen from "../Forms/Oxygen";
import Contact from "../Component/Pages/Contact";
import Heroes from "../Component/Pages/Heroes";
import Team from "../Component/Pages/Team";
import Login from "../Component/Pages/Login";
import Setting from "../Component/Pages/Setting";
import Patient from "../Forms/Patient";
import GroupIcon from "@material-ui/icons/Group";
import StarsIcon from "@material-ui/icons/Stars";
import MoneyIcon from "@material-ui/icons/Money";
import Donate from "../Component/Pages/Donate";
interface RoutesInterface {
  label: string;
  path: string;
  component: React.FC;
  icon?: JSX.Element;
  isProtected?: boolean;
}

interface ArrayRoutesInterface extends Array<RoutesInterface> {}
//alternative export const SideBarPrimaryRoutes:RoutesInterface[] = [
export const SideBarPrimaryRoutes: ArrayRoutesInterface = [
  { label: "About", path: "/aboutus", component: About, icon: <HomeIcon /> },
  {
    label: "Patient",
    path: "/patient",
    component: Patient,
    icon: <LocalHospitalIcon />,
  },

  // {
  //   label: `Donor`,
  //   path: "/donor",
  //   component: Home,
  //   icon: <InvertColorsIcon />,
  //   isProtected: false,
  // },

  // {
  //   label: "Oxygen Donor",
  //   path: "/oxygen",
  //   component: Oxygen,
  //   icon: <SpeedIcon />,
  // },

  {
    label: "Team",
    path: "/team",
    component: Team,
    icon: <GroupIcon />,
  },
  // {
  //   label: "Our Heroes",
  //   path: "/heroes",
  //   component: Heroes,
  //   icon: <StarsIcon />,
  // },
  {
    label: "Donate",
    path: "/donate",
    component: Home,
    icon: <ContactsIcon />,
  },

  {
    label: "Contact",
    path: "/contact",
    component: Contact,
    icon: <ContactsIcon />,
  },
  // {label:'Login', path:"/login",component:Login, icon: <AccountBoxIcon  /> }
];
export const HeaderTabsList = [
  //SideBarPrimaryRoutes[0],
  //SideBarPrimaryRoutes[1],
  // {label:"One", path:"/drafts" ,component:Home , icon: <HomeIcon/> ,  isProtected:true},
  { label: "About", path: "/aboutus", component: About, icon: <HomeIcon /> },
  // {
  //   label: `Donor`,
  //   path: "/donor",
  //   component: Home,
  //   icon: <InvertColorsIcon />,
  //   isProtected: false,
  // },

  // {
  //   label: "Oxygen Donor",
  //   path: "/oxygen",
  //   component: Oxygen,
  //   icon: <SpeedIcon />,
  // },

  {
    label: "Patient",
    path: "/patient",
    component: Patient,
    icon: <LocalHospitalIcon />,
  },

  {
    label: "Team",
    path: "/team",
    component: Team,
    icon: <GroupIcon />,
  },
  // {
  //   label: "Our Heroes",
  //   path: "/heroes",
  //   component: Heroes,
  //   icon: <StarsIcon />,
  // },
  {
    label: "Donate",
    path: "/donate",
    component: Home,
    icon: <ContactsIcon />,
  },
  {
    label: "Contact",
    path: "/contact",
    component: Contact,
    icon: <ContactsIcon />,
  },

  // {label:"Vision", path:"/four",component:Four , icon: <MailIcon/>}
];

export const SideBarSecondaryRoutes = [
  {
    label: "Our Partners",
    path: "/partners",
    component: Sponsor,
    icon: <MoneyIcon />,
  },
];

export const DefaultRoute = [
  {
    label: "helpzindaginobleadmin",
    path: "/helpzindaginobleadmin",
    component: Admin,
  },
  {
    label: "doctoradmin",
    path: "/doctoradmin",
    component: DoctorAdmin,
  },
  { label: "aboutus", path: "/", component: About },
];
