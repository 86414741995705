import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Sidebar from "../../Sidebar/Sidebar";
import HeaderTabs from "../../Tabs/HeaderTabs";
const title = `Help Zindagi `;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: "fixed",
      top: "0px",
      zIndex: 100,
      width: "100vw",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      zIndex: 1000,
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      maxWidth: "323px",
      // display: "grid",
      // placeItems: "center",
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        maxWidth: "276px",
      },
    },
    logoContainer: {
      position: "relative",
      width: "100%",
      display: "grid",
      // placeItems: "center",

      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        display: "block",
        position: "absolute",
      },
    },
    appbar: {
      // background:'white',
      //color:"black"
    },
    headerTab: {
      [theme.breakpoints.down("sm")]: {
        position: "fixed",
        bottom: "0px",
        left: "0px",
        width: "100vw",
        borderTop: "1px solid",
        zIndex: 100,
        background: "#f5f5f5",
      },
    },
  })
);

function Header() {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [sideBarOpen, setSideBarOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    handleDrawer(true);
  };
  const handleSidebar = () => {
    handleDrawer(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawer = (sidebar: boolean) => {
    setSideBarOpen(sidebar);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Sidebar handleDrawer={handleDrawer} open={sideBarOpen} />
        <AppBar position="static" className={classes.appbar} color={"default"}>
          <Toolbar>
            <IconButton
              onClick={handleSidebar}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.logoContainer}>
              {/* <Typography variant="h6" className={classes.title}>
                {title}
              </Typography> */}
              <img className={classes.logo} src="logo-60x60.png" />
            </div>
            <Typography variant="h6" className={classes.title}>
              {/* {title} */}
            </Typography>
            <div className={classes.headerTab}>
              <HeaderTabs />
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </React.Fragment>
  );
}

export default Header;
