import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import useForm from "../Hooks/useForm";
import { validateLogin, validateOnChange } from "./Validation/validation";
import Gender from "../Component/UI/Gender";
import Blooodgroup from "../Component/UI/Bloodgroup";
import "../Component/mystyle.css";
import Bloodgroup from "../Component/UI/Bloodgroup";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  savePlasmaDonor,
  getAllRecord,
  savePatientDailyStatus,
  firebase,
  getDailyStatus,
} from "../DB/api";
import Popup from "../Component/UI/Alert/Popup";
import usePopup from "../Hooks/usePopup";
import { LaptopChromebook } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import Rtpcr from "../Component/UI/Rtpcr";
import DonatePlasma from "../Component/UI/DonatePlasma";
import Diseases from "../Component/UI/Diseases";
import Alcohal from "../Component/UI/Alcohal";
import "../Component/mystyle.css";

import DischargeReport from "../Component/UI/DischargeReport";
import Vaccinated from "../Component/UI/Vaccinated";
import PlasamaDonorStatus from "../Component/UI/PlasamaDonorStatus";
import { Input, MobileStepper } from "@material-ui/core";
import { useStateValue } from "../StateProvider/StateProvider";
import LookingFor from "../Component/UI/LookingFor";
import IsolationPatientStatus from "../Component/UI/IsolationPatientStatus";
import Chart from "../Component/UI/Chart";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      mwidth: "96%",
    },
  },
}));
function PatientIsolationForm(props: any) {
  const [state, dispatchState] = useStateValue();
  const rowData = { ...props };
  const doc: any = [];
  const classes = useStyles();
  const [sugarlevelData, setsugarlevelData] = useState({
    date: [],
    data: [],
  });
  const [bloodpresaureData, setbloodpresaureData] = useState({
    date: [],
    data: [],
  });
  const [oxygensaturationData, setoxygensaturationData] = useState({
    date: [],
    data: [],
  });
  const [tempratureData, settempratureData] = useState({
    date: [],
    data: [],
  });
  const [enableSubmit, setenableSubmit] = useState(true);
  const [images, setImages] = useState([]);
  const { open, setOpen, setMessage, message, setVariant, variant } =
    usePopup();

  const DownLoadFiles = () => {
    const mobile = filedValue.patientmobile;
    const ref = `/doc/${mobile}/${props.rowData.id}/`;
    const storageRef = firebase.storage().ref(ref);

    const ImageRef = filedValue.documents.map((item: any) =>
      storageRef.child(item).getDownloadURL()
    );

    // Get the download URL
    Promise.all(ImageRef)
      .then((url) => {
        const newImage = images;
        newImage.push(url);
        setImages([...url]);
        // Insert url into an <img> tag to "download"
      })
      .catch(() => {});
  };

  const onSubmit = async () => {
    const result = await savePatientDailyStatus(
      {
        ...values,
        id: props.rowData.id,
        laastModifiedBy: state.loginReducerState.name,
      },
      props.rowData
    );
    setMessage(result.message);
    if (result.error) {
      setVariant("error");
    } else {
      setVariant("success");
    }
    clearValue();
    setOpen(true);
  };
  const { values, errors, handleChange, handleSubmit, clearValue } = useForm(
    onSubmit,
    validateLogin,
    validateOnChange
  );
  const uploadFiles = (imageAsFile: any) => {
    const name = props.rowData.id + "_" + imageAsFile.name.replaceAll(" ", "");
    const mobile = filedValue.patientmobile;

    const ref = `/doc/${mobile}/${props.rowData.id}/`;
    const storageRef = firebase.storage().ref(ref);

    const ImageRef = storageRef.child(name);
    ImageRef.put(imageAsFile).then(() => {
      filedValue.documents.push(name);
      DownLoadFiles();
      onSubmit();
    });
  };

  const [progress, setProgress] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [uploadImage, setUploadedImage] = useState([]);

  const documentsUpload = (e: any) => {
    let { files } = e.target;
    filedValue.documents = props.rowData.documents || [];
    [...files].forEach(function (item: any) {
      uploadFiles(item);
    });
  };
  const filedValue = Object.assign(values, {
    documents: values.documents || props.rowData.documents || [],
    status: values.status || props.rowData.status || "",
    patientname: values.patientname || props.rowData.patientname,
    comment: values.comment || "",
    temprature: values.temprature || "",
    oxygensaturation: values.oxygensaturation || "",
  });

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setAgreed(event.target.checked);
    if (!event.target.checked) {
      event.target.value = "false";
    }
    handleChange(event);
  };
  const fetchChartData = async () => {
    const data = await getDailyStatus(props.rowData.id);
    const tempraature_data = [];
    const tempraature_date = [];

    const sugar_data = [];
    const sugar_date = [];
    const blood_data = [];
    const blood_date = [];

    const oxygen_data = [];
    const oxygen_date = [];
    for (let k = 0; k < data.length; k++) {
      if (data[k].sugarlevel) {
        sugar_data.push(data[k].sugarlevel);
        sugar_date.push(data[k].date);
      }
      if (data[k].oxygensaturation) {
        oxygen_data.push(data[k].oxygensaturation);
        oxygen_date.push(data[k].date);
      }
      if (data[k].bloodpresaure) {
        blood_data.push(data[k].bloodpresaure);
        blood_date.push(data[k].date);
      }
      if (data[k].temprature) {
        tempraature_data.push(data[k].temprature);
        tempraature_date.push(data[k].date);
      }
    }
    settempratureData({
      data: [...tempraature_data],
      date: [...tempraature_date],
    });
    setsugarlevelData({
      data: [...sugar_data],
      date: [...sugar_date],
    });
    setoxygensaturationData({
      data: [...oxygen_data],
      date: [...oxygen_date],
    });
    setbloodpresaureData({
      data: [...blood_data],
      date: [...blood_date],
    });
  };
  const filedError = Object.assign(errors);
  useEffect(() => {
    DownLoadFiles();
    fetchChartData();
  }, [progress]);
  return (
    <React.Fragment>
      <Popup
        open={open}
        message={message}
        variant={variant}
        setOpen={setOpen}
      />
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          id="patientname"
          name="patientname"
          label="Patient Name"
          value={filedValue.patientname || ""}
          helperText={filedError.patientname}
          disabled
          onChange={handleChange}
          //required
          fullWidth
          //  variant="filled"
          // autoComplete="given-name"
        />
        <TextField
          id="temprature"
          name="temprature"
          label="Temprature"
          value={filedValue.temprature || ""}
          error={filedError.temprature ? true : false}
          helperText={filedError.temprature}
          onChange={handleChange}
          type={"number"}
          //required
          fullWidth
          //  variant="filled"
          // autoComplete="given-name"
        />
        <TextField
          id="oxygensaturation"
          name="oxygensaturation"
          label="Oxygen Saturation "
          value={filedValue.oxygensaturation || ""}
          error={filedError.oxygensaturation ? true : false}
          helperText={filedError.oxygensaturation}
          onChange={handleChange}
          //required
          fullWidth
          type={"number"}
          //  variant="filled"
          // autoComplete="given-name"
        />
        <TextField
          id="bloodpresaure"
          name="bloodpresaure"
          label="Blood Presaure"
          value={filedValue.bloodpresaure || ""}
          error={filedError.bloodpresaure ? true : false}
          helperText={filedError.bloodpresaure}
          onChange={handleChange}
          //required
          fullWidth
          type={"number"}
          //  variant="filled"
          // autoComplete="given-name"
        />
        <TextField
          id="sugarlevel"
          name="sugarlevel"
          label="Sugar Level"
          value={filedValue.sugarlevel || ""}
          error={filedError.sugarlevel ? true : false}
          helperText={filedError.sugarlevel}
          onChange={handleChange}
          //required
          fullWidth
          type={"number"}
          //  variant="filled"
          // autoComplete="given-name"
        />
        <IsolationPatientStatus
          onChange={handleChangeCheckbox}
          status={filedValue.status || ""}
        />
        <TextField
          id="comment"
          name="comment"
          label="Comment"
          value={filedValue.comment || ""}
          error={filedError.comment ? true : false}
          helperText={filedError.comment}
          onChange={handleChange}
          //required
          fullWidth
          //  variant="filled"
          // autoComplete="given-name"
        />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant={"h6"}>Daily Report</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="chartcontainer">
              <Chart
                label={"Temprature"}
                data={tempratureData.data}
                axisX={tempratureData.date}
                text="Avrage Body Temprature"
                progress={progress}
              />
              <Chart
                label={"Sugar Level"}
                data={sugarlevelData.data}
                axisX={sugarlevelData.date}
                text="Avrage Sugar Level"
                progress={progress}
              />
              <Chart
                label={"Oxygen Saturation"}
                data={oxygensaturationData.data}
                axisX={oxygensaturationData.date}
                text="Avrage Oxygen Saturation"
                progress={progress}
              />
              <Chart
                label={"Blood Preasaure"}
                data={bloodpresaureData.data}
                axisX={bloodpresaureData.date}
                text="Avrage Blood Preasaure"
                progress={progress}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />

        <Button
          type="submit"
          variant="contained"
          color="secondary"
          //className="mybutton"
          fullWidth
          size="large"
          startIcon={<SendIcon />}

          //  onClick={handleSubmit}
        >
          SUBMIT{" "}
        </Button>
        <br />
        <br />

        <input
          color="primary"
          accept="*"
          type="file"
          onChange={documentsUpload}
          id="icon-button-file"
          style={{ display: "none" }}
        />
        <label htmlFor="icon-button-file">
          <Button
            variant="contained"
            component="span"
            size="large"
            color="primary"
            fullWidth
          >
            Upload Documents
          </Button>
        </label>
        <br />

        <div className="imageView">
          {images.map((image) => (
            <img src={image} height="50px" width="100px"></img>
          ))}
        </div>
      </form>
    </React.Fragment>
  );
}

export default PatientIsolationForm;
