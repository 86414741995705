import { Divider, Typography } from "@material-ui/core";
import React, { useState } from "react";

import "../mystyle.css";
import TeamCard from "../UI/TeamCard";
function Team() {
  return (
    <React.Fragment>
      <h3 className="myheading">Core Volunteers</h3>
      <Divider />
      <br />
      <div className="teamcontainer">
        <TeamCard
          name={"Vishwa Pachauri"}
          image={"vishwa.png"}
          message={"A Software Technocrat"}
          degree={"M.Tech. IIT, Kharagpur"}
        />
        <TeamCard
          name={"Puneet Kumar Jain"}
          image={"puneet1.png"}
          message={"A Software Technocrat"}
          degree={"(M.S. BITS, Pilani)"}
        />
        <TeamCard
          name={"Amit Saini"}
          image={"amit.png"}
          message={"A Software Technocrat"}
          degree={"(MCA , UPTU)"}
        />
      </div>
      <br />
      <h3 className="myheading">Medical Team</h3>
      <Divider />
      <br />
      <div className="teamcontainer">
        <TeamCard
          name={"Dr. Pooja Garg"}
          image={"pooja.png"}
          message={"MD (Physician)"}
        />
        <TeamCard
          name={"Dr. Amit Sharma"}
          image={"amitshrma.png"}
          message={"MD (Physician)"}
        />
        <TeamCard
          name={"Dr. Sunil Gupta"}
          image={"sunil.png"}
          message={"MBBS,DCH,DNB (Pediatrics)"}
        />
      </div>
      <br />
      <h3 className="myheading">Operation Team</h3>
      <Divider />
      <br />
      <div className="teamcontainer">
        <TeamCard
          name={"Praveen Kumar"}
          image={"Praveen.png"}
         // message={"Head of Operations, Bharatpur (Rajasthan)"}
        />
        <TeamCard
          name={"Dr. Vinod Dixit"}
          image={"dikshit.png"}
          message={"(Also Secretary of Sarvajanik Seva Sansthan-NGO)"}
          degree={"Head of Operations, Mathura (UP)"}
          padding={"0px"}
        />
        <TeamCard
          name={"Manish Sharma"}
          image={"manish.png"}
          display="hidden"
        />
      </div>{" "}
      <br />
      <h3 className="myheading">Legal & Compliances Team</h3>
      <Divider />
      <br />
      <div className="teamcontainer">
        <TeamCard
          name={"Manish Pachori"}
          image={"manishp.png"}
          message={"Chartered Accountant"}
        />
        <TeamCard
          name={"Preeti Kumari "}
          image={"preeti.png"}
          message={"Company Secretary, LLB"}
        />
        <TeamCard
          name={"Manish Sharma"}
          image={"manish.png"}
          display="hidden"
        />
      </div>
      <br />
      <h3 className="myheading">Administrative Associates</h3>
      <Divider />
      <br />
      <div className="teamcontainer">
        <TeamCard
          name={"Ashish Dubey"}
          image={"ashish.png"}
          message={"Inspector, Delhi Police"}
        />
        <TeamCard
          name={"Manish Sharma"}
          image={"manish.png"}
          display="hidden"
        />
        <TeamCard
          name={"Manish Sharma"}
          image={"manish.png"}
          display="hidden"
        />
      </div>
      <br />
      <h3 className="myheading">Volunteers</h3>
      <Divider />
      <br />
      <div className="teamcontainer">
        <TeamCard name={"Purnima Pal"} image={"purnima.png"} message={""} />
        <TeamCard name={"Priyansh Jain"} image={"priyansh.png"} message={""} />
        <TeamCard name={"Shashank"} image={"Shashank.png"} message={""} />
        <TeamCard name={"Atishay Jain"} image={"atishay.png"} message={""} />
        <TeamCard name={"Nishant"} image={"nishant.png"} message={""} />
        <TeamCard name={"Dhruv"} image={"dhruv.png"} message={""} />
        <TeamCard name={"Harman"} image={"harman.png"} message={""} />
        <TeamCard name={"Sumalatha K"} image={"sumalatha.png"} message={""} />
        <TeamCard name={"Harshit"} image={"harshit.png"} message={""} />
        <TeamCard name={"Sejal"} image={"sejal.png"} message={""} />
        <TeamCard name={"Jai Sharma"} image={"jai.png"} message={""} />
      </div>
    </React.Fragment>
  );
}

export default Team;
