import React, { useState } from "react";
import Plasma from "./Plasma";
import "../Component/mystyle.css";
import { savePlasmaDonor } from "../DB/api";
import usePopup from "../Hooks/usePopup";
import PlasmaDonor from "./PlsmaDonor";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Oxygen from "./Oxygen";
import ToggleButton from "../Component/UI/ToggleButton";
import Donate from "../Component/Pages/Donate";

export default () => {
  const [donorType, setDonorType] = useState("Oxygen");
  const [donorTab, setDonorTab] = useState("others");

  const handleDonorType = (e: any) => {
    setDonorType(e.target.value);
    console.log(e.target.value);
  };
  console.log("donorTab", donorTab);
  return (
    <div className="browserHeight">
      <ToggleButton donorTab={donorTab} setDonorTab={setDonorTab} />
      {donorTab === "others" && (
        <React.Fragment>
          {/* <div className="donorContainer">
            <FormControl component="fieldset">
              
              <RadioGroup
                style={{ display: "block" }}
                aria-label="donorType"
                name="donorType"
                value={donorType}
                onChange={handleDonorType}
              >
                <FormControlLabel
                  value="Oxygen"
                  control={<Radio />}
                  label={
                    <p className="mytext">
                      I want to donate Oxygen Concentrator | Cylinder{" "}
                    </p>
                  }
                />
                <FormControlLabel
                  value="Plasma"
                  control={<Radio />}
                  label={<p className="mytext">I want to donate Plasma</p>}
                />
              </RadioGroup>
            </FormControl>
          </div> */}
          <div>
            {/* {donorType === "Plasma" && <Plasma />} */}
            {donorType === "Oxygen" && <Oxygen />}
          </div>
        </React.Fragment>
      )}
      {donorTab === "once" && <Donate />}
      {donorTab === "monthly" && <Donate />}
    </div>
  );
};
