import Rect from "react";
import { useState, useEffect } from "react";

const usePopup = () => {
  const [open, setOpen] = useState(false);
  const [variant, setVariant] = useState("success");
  const [message, setMessage] = useState("");
  useEffect(() => {}, []);
  return {
    setOpen,
    setVariant,
    setMessage,
    open,
    variant,
    message,
  };
};

export default usePopup;
