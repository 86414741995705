import React, { useState } from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { saveQuery } from "../../DB/api";
import Popup from "../UI/Alert/Popup";
import usePopup from "../../Hooks/usePopup";
import useForm from "../../Hooks/useForm";
import Divider from "@material-ui/core/Divider";

import {
  validateLogin,
  validateOnChange,
} from "../../Forms/Validation/validation";

import "../mystyle.css";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));
function Contact() {
  const [disable, setDisable] = useState(false);
  const classes = useStyles();
  const {
    open,
    setOpen,
    setMessage,
    message,
    setVariant,
    variant,
  } = usePopup();

  const onSubmit = async (a: any) => {
    const data = { ...values };
    const result = await saveQuery(values);
    setDisable(true);

    setMessage(result.message);
    if (result.error) {
      setVariant("error");
    } else {
      setVariant("success");
    }
    clearValue();
    setDisable(false);
    setOpen(true);
  };
  const { values, errors, handleChange, handleSubmit, clearValue } = useForm(
    onSubmit,
    validateLogin,
    validateOnChange
  );

  let filedValue = Object.assign(values);

  const filedError = Object.assign(errors);

  return (
    <React.Fragment>
      <Popup
        open={open}
        message={message}
        variant={variant}
        setOpen={setOpen}
      />
      <div className="myhead">
        <h2 className="mytitle"> Contact Us</h2>
        <hr className="texttitle" />
      </div>
      <Container className="contactpage">
        <div className="sam">
          <h3 className="myheading">Contact Us</h3>
          <Typography
            className="contactdetail"
            variant="subtitle2"
            gutterBottom
          >
            <MailIcon className="myicon" /> contact@helpzindagi.org
          </Typography>
          <Typography variant="caption" gutterBottom>
            (We will respond you within 24 hours)
          </Typography>
          <br />
          <Grid container spacing={3}>
            <Grid item md={5}>
              <p className="mytext">
                <br />
                हमारा एकमात्र उद्देश्य जरूरतमंद और गरीब लोगो की मदद करना है।
                हमने अपने स्वयं की निधियों से शुरुआत की और जितने लोगो की मदद हम
                कर सकते थे की। जल्दी ही कई अन्य प्रायोजकों और सहयोगियों ने हमें
                साथ दिया और हमें इस अच्छे कार्य के प्रभाव को बढ़ाने में सहयोग
                किया। हम उन सभी प्रायोजकों और सहयोगियों के तहे दिल से आभारी है।
                <br />
                <br /> हजारों लोगों के जीवन में बदलाव लाने में हमारी मदद करने के
                लिए बहुत-बहुत धन्यवाद।
                <br />
                <br />
                अगर आप भी इस नेक काम के लिए हमारी मदद करना चाहते हैं, तो आप हमसे
                संपर्क कर सकते हैं।
                <br />
                <br />
                हम अभी जरूरतमंद लोगो की मदद करने में बहुत ज्यादा व्यस्त है लेकिन
                कुछ ही दिनों में हम अपने अभी बड़े स्पोंसर्स और दान देने वालो की
                लिस्ट अपनी साइट पर दिखाएँगे।
                <br />
                <br />
              </p>
              <Divider />
              <br />
              <p className="mytext">
                <b>
                HelpZindagi Foundation is  registered under section 8 (1) of the
                Companies Act, 2013 and is invloved in various charitable
                activities as an NGO.
                <br />
                <br />
                (Registration Number: 381559)    (CIN: U85300DL2021NPL381559)
                </b>
                <br />
                <br />
                We are working for a noble cause with the single intention of
                saving lives. We started with our own funds and helped as many
                lives we could. Eventually many other partners and volunteers
                joined us and helped to spread the impact of this noble cause ,
                best in their capacity.
                <br />
                <br /> Ton of thanks to them for joining and helping us in
                making a difference to thousands of lives.
                <br />
                <br />
                You can also contact us if you are interested in helping us by
                your donations or as a volunteer, for this noble cause.
                <br />
                <br />
                We are extremely busy now helping individuals in need, but
                shortly we will publish list of all our major partners and
                donors on our site.
                <br />
                <br />
                <br />
              </p>
              {/* <FacebookIcon className="socialicon fb" />{" "}
              <InstagramIcon className="socialicon insta" />
              <TwitterIcon className="socialicon twitter" />
              <YouTubeIcon className="socialicon tube" /> */}
            </Grid>
            <Grid item md={7}>
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={filedValue.name || ""}
                  error={filedError.name ? true : false}
                  helperText={filedError.name}
                  onChange={handleChange}
                  required
                  fullWidth
                  //  variant="filled"
                  // autoComplete="given-name"
                />
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  value={filedValue.email || ""}
                  error={filedError.email ? true : false}
                  helperText={filedError.email}
                  onChange={handleChange}
                  required
                  fullWidth
                  //  variant="filled"
                  // autoComplete="given-name"
                />
                <TextField
                  id="phone"
                  name="phone"
                  label="Phone"
                  value={filedValue.phone || ""}
                  error={filedError.phone ? true : false}
                  helperText={filedError.phone}
                  onChange={handleChange}
                  required
                  fullWidth
                  //  variant="filled"
                  // autoComplete="given-name"
                />
                <TextField
                  id="message"
                  name="message"
                  label="Message"
                  value={filedValue.message || ""}
                  error={filedError.message ? true : false}
                  helperText={filedError.message}
                  onChange={handleChange}
                  required
                  fullWidth
                  multiline
                  rows={4}
                  // variant="filled"
                  //  variant="filled"
                  // autoComplete="given-name"
                />
                <Button
                  variant="contained"
                  color="secondary"
                  className="mybutton"
                  type="submit"
                  disabled={disable}
                  startIcon={<SendIcon />}
                >
                  SUBMIT{" "}
                </Button>
              </form>
            </Grid>
          </Grid>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Contact;
