import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Roouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import {
  initialStateLanguage,
  LanguageReducer,
} from "./Reducer/LanguageReducer";
import { initialStateLogin, LoginReducer } from "./Reducer/LoginReducer";
import { initialStateTheme, ThemeReducer } from "./Reducer/ThemeReducer";
import { StateProvider, useStateValue } from "./StateProvider/StateProvider";

const mainReducer = (props: any, action: any) => {
  return {
    languageReducerState: LoginReducer(
      props.LanguageReducerState || {},
      action
    ),
    loginReducerState: LoginReducer(props.loginReducerState || {}, action),
    themeReducerState: ThemeReducer(props.activeTabReducerState || {}, action),
  };
};
const combinestate = {
  themeReducerState: initialStateTheme,
  loginReducerState: initialStateLogin,
  languageReducerState: initialStateLanguage,
};
ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={combinestate} reducer={mainReducer}>
      <Roouter>
        <CssBaseline />
        <App />
      </Roouter>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
