import React from "react";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default (props: any) => {
  const [value, setValue] = React.useState(props.diseases || "");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = props.diseases;
    if (event.target.checked) {
      value += " " + event.target.value;
    } else {
      value = props.diseases.replaceAll(event.target.value, "");
    }
    event.target.value = value.toLowerCase();
    //setValue((event.target as HTMLInputElement).value);
    props.onChange(event);
  };

  return (
    <>
      <FormLabel component="legend">
        Do you suffer from any of the following diseases?
      </FormLabel>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            name="diseases"
            value="diabetes"
            checked={props.diseases.indexOf("diabetes") >= 0 ? true : false}
          />
        }
        label="Diabetes"
      />

      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            name="diseases"
            value="blood"
            checked={props.diseases.indexOf("blood") >= 0 ? true : false}
          />
        }
        label="Blood"
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            name="diseases"
            value="pressureliver"
            checked={
              props.diseases.indexOf("pressureliver") >= 0 ? true : false
            }
          />
        }
        label="PressureLiver"
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            name="diseases"
            value="diseaselung"
            checked={props.diseases.indexOf("diseaselung") >= 0 ? true : false}
          />
        }
        label="DiseaseLung"
      />
    </>
  );
};
/*

<FormControl component="fieldset">
      <FormLabel component="legend">
        Do you suffer from any of the following diseases?
      </FormLabel>
      <RadioGroup
        style={{ display: "block" }}
        aria-label="Diseases"
        name="diseases"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="diabetes"
          control={<Radio />}
          label="Diabetes"
        />
        <FormControlLabel value="blood" control={<Radio />} label="Blood" />
        <FormControlLabel
          value="pressureliver
"
          control={<Radio />}
          label="PressureLiver
"
        />
        <FormControlLabel
          value="diseaselung
"
          control={<Radio />}
          label="DiseaseLung
"
        />
        <FormControlLabel value="none" control={<Radio />} label="None" />
      </RadioGroup>
    </FormControl>

*/
