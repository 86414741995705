import React, { useEffect, useState } from "react";
import { Divider, Paper, Theme, Typography } from "@material-ui/core";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Skeleton from "@material-ui/lab/Skeleton";
import TestimonialCard from "./TestimonialCard";
import ReviewCaard from "./ReviewCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      // backgroundColor: theme.palette.background.paper,
    },
    mygridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
      width: "95vw",
    },
  })
);

export default function RecentVendorSearch() {
  const classes = useStyles();
  const [vendors, setVendors] = useState([]);

  //   const fetchVendor = async () => {
  //     const data = await getAllVendor();
  //     setVendors(data);
  //   };

  //   useEffect(() => {
  //     fetchVendor();
  //   }, []);

  return (
    <React.Fragment>
      <h3 className="myheading">Testimonial</h3>
      <Divider />
      <br />
      <div className="reviewContainer">
        <TestimonialCard
          date="July 16, 2021"
          title={"Dr. Amit Sharma"}
          city={"Radhakund"}
          content={`videos/amit.mp4`}
          position="left"
        />
        <TestimonialCard
          date="July 16, 2021"
          title={"Puneet Kumar Jain"}
          city={"Radhakund"}
          content={`videos/puneet.mp4`}
        />
      </div>
    </React.Fragment>
  );
}
