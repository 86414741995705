import React from "react";
import { Bar } from "react-chartjs-2";


export default (props) => {
  console.log(props);
  const text = props.text;
  const state = {
  labels: props.axisX,//["12/04","12/04","12/04","12/04","12/04","12/04","12/04","12/04","12/04","12/04","12/04"],
  datasets: [
    {
      label: props.label,
      backgroundColor: "rgba(75,192,192,1)",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 2,
      data: props.data,//[96,97.5,98,99,101,102,103,104,105,101,103,109],
    },
  ],
};

  
    return (
      
        <Bar
          data={state}
          options={{
            title: {
              display: true,
              text: {text},
              fontSize: 10,
            },
            legend: {
              display: true,
              position: "right",
            },
          }}
        />
      
    );
  
}
