import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default (props: any) => {
  const [value, setValue] = React.useState(props.vaccinated || "yes");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    props.onChange(event);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        Have you been vaccinated in last 14 days?
      </FormLabel>
      <RadioGroup
        style={{ display: "block" }}
        aria-label="vaccinated"
        name="vaccinated"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};
