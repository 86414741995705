
import React, { Suspense } from "react";
import {SideBarPrimaryRoutes,SideBarSecondaryRoutes,DefaultRoute,HeaderTabsList} from './routesList';
import { Route,  Switch,Redirect } from "react-router-dom";
import { useStateValue } from "../StateProvider/StateProvider";

const routes = [
  ...SideBarPrimaryRoutes,
  ...SideBarSecondaryRoutes,
  ...HeaderTabsList,
  ...DefaultRoute
]; 



 const Routes = () => {
  const [state,dispatchState] = useStateValue();
  const isAuthenticated  = state.loginReducerState.isLoggedIn;
  
  const MyPrivateRoute = ({ component: Component, ...rest }) => (
         <Route {...rest} render={(props) => (
           isAuthenticated
          ? <Component {...props} />
          : <Redirect
          to={{
            pathname: "/login",
            //state: { from: location }
          }}
        />
      )} />
    )
  const RouteRender =()=> routes.map(route=> route.isProtected 
                         ? <MyPrivateRoute key={route.path} component={route.component} path={route.path} /> 
                         : <Route key={route.path} component={route.component} path={route.path} /> )
  
  return (
    <Suspense fallback={'loading....'}>
    <Switch>
        {RouteRender()}
    </Switch>
    </Suspense>
  );
};
export default  Routes 