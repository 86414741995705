import React,{lazy,Suspense, useEffect} from 'react';
import { createStyles, makeStyles, Theme  } from '@material-ui/core/styles';
import  Container from '@material-ui/core/Container';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            background:'grey',
            position:'absolute',
            top:'56px',
            padding:theme.spacing(2),
            boxSizing:'border-box',
            
            [theme.breakpoints.up('sm')]:{
              top:'64px',
              padding:theme.spacing(3),
            }
            
          }
    }),
);
function AppContainer(props:any) {
  
 const classes = useStyles();   
  return (
    <Container className={classes.container} maxWidth="xl">
       {props.children}
       
    </Container>     
  );
}

export default AppContainer;
