import React, { useEffect, useState } from "react";
import Login from "./Login";
import { useStateValue } from "../StateProvider/StateProvider";
import Dashboard from "./Dashboard";
import Loading from "../Loading";
import { firebaseObj } from "../DB/api";

import { SUCCESS, LOGOUT } from "../Reducer/LoginReducer";
const Admin = () => {
  const [state, dispatchState] = useStateValue();
  const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000);
  // }, [state.loginReducerState.isLoggedIn]);
  const Fbauth = async () => {
    firebaseObj.auth().onAuthStateChanged(function (user: any) {
      if (user) {
        console.log("user", user);
        dispatchState({ type: SUCCESS, payload: { user } });
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    Fbauth();
  }, []);

  console.log(state.loginReducerState.isLoggedIn, isLoading);
  return (
    <React.Fragment>
      {isLoading && <Loading />}
      {!state.loginReducerState.isLoggedIn && !isLoading ? (
        <Login />
      ) : (
        <Dashboard />
      )}
    </React.Fragment>
  );
};
export default Admin;
