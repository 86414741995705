import React from "react";
import Slider from "../Slider";
import Footer from "../Footer/Footer";
import About from "./About";
import Donor from "../../Forms/Donor";
function Home() {
  return (
    <React.Fragment>
      <Donor/>
      {/* <Footer /> */}
    </React.Fragment>
  );
}

export default Home;
