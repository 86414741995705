import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default (props: any) => {
  const [value, setValue] = React.useState(props.lookingfor || "cylnders");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    props.onChange(event);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Looking for</FormLabel>
      <RadioGroup
        style={{ display: "block" }}
        aria-label="lookingfor"
        name="lookingfor"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="cylnders" control={<Radio />} label="Oxygen" />
        <FormControlLabel value="plasma" control={<Radio />} label="Plasma" />
        {/* <FormControlLabel
          value="medicine"
          control={<Radio />}
          label="Medicine"
        /> */}
      </RadioGroup>
    </FormControl>
  );
};
