import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  space: {
    padding: "0px",
  },
});

export default function TeamCard(props: any) {
  const classes = useStyles();

  return (
    <Card
      className="teamCardContainer"
      style={{ visibility: props.display === "hidden" ? "hidden" : "visible" }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          // height="260"
          image={`/teams/${props.image}`}
          title="Contemplative Reptile"
        />
        <CardContent
          style={{ padding: props.padding ? props.padding : "16px" }}
        >
          <Typography gutterBottom variant="h6" align="center">
            {props.name}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            //component="p"
            align="center"
          >
            {props.message}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            //component="p"

            align="center"
          >
            {props.degree}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>
  );
}
