import React, { useState } from "react";
import { Divider, ListItemAvatar, Typography } from "@material-ui/core";
import PatientDetailsCard from "../Component/UI/PatinetDetailsCard";
import { Button } from "@material-ui/core";

import "../../src/Component/mystyle.css";
import Link from "@material-ui/core/Link";
export default (props: any) => {
  return (
    <React.Fragment>
      <h3 className="myheading">
        Patients{" "}
        <Link href="#" variant="body2" onClick={props.addPatient}>
          Add More Patient
        </Link>
      </h3>
      <Divider />
      <br />
      <div className="reviewContainer">
        {props.patients.map((item: any) => (
          <PatientDetailsCard
            id={item.id}
            name={item.name}
            mobile={item.mobile}
            age={item.age}
            buttonText="Book Appointmant"
            appointmentHandler={props.bookAppointment}
            setSelectedDate={props.setSelectedDate}
          />
        ))}
      </div>
      <br />
      <br />
      {props.appointment.length > 0 && (
        <React.Fragment>
          <h3 className="myheading">Appointments</h3>
          <Divider />
          <br />
          <div className="reviewContainer">
            {props.appointment.map((item: any) => (
              <PatientDetailsCard
                id={item.id}
                name={item.name}
                mobile={item.mobile}
                age={item.age}
                buttonText="Cancel Appointmant"
                appointmentHandler={props.cancelAppointment}
                isDisableDate={true}
                date={item.date}
                token ={item.token}
              />
            ))}
          </div>
        </React.Fragment>
      )}

      <br />

      <br />
    </React.Fragment>
  );
};
