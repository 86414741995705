export function validateLogin(values, formField) {
  let errors = {};
  emailValidation(values.email, errors);
  nameValidation(values, errors);
  return errors;
}

export function validateOnChange(values) {
  let errors = {};
  if (values.name === "email") {
    emailValidation(values.value, errors);
  }
  if (values.name.toLowerCase().indexOf("mobile") !== -1) {
    mobileValidation(values.value, errors, values.name);
  }
  blankValidation(values, errors);
  return errors;
}

function blankValidation(obj, errors) {
  if (!obj.value) {
    errors[obj.name] = `${obj.name} is required`;
  }
  return errors;
}
function nameValidation(value, errors) {
  blankValidation(value, errors);
  return errors;
}
function emailValidation(value, errors) {
  if (!value) {
    errors.email = "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(value)) {
    errors.email = "Email address is invalid";
  }
  return errors;
}

function mobileValidation(value, errors, fieldname) {
  if (!value || value.length < 10) {
    errors[fieldname] = `Mobile Number should be 10 digit`;
  }
  return errors;
}
