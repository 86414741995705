import React, { useEffect, useState } from "react";
import { Divider, Paper, Theme, Typography } from "@material-ui/core";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Skeleton from "@material-ui/lab/Skeleton";
import TestimonialCard from "./TestimonialCard";
import ReviewCaard from "./ReviewCard";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      // backgroundColor: theme.palette.background.paper,
    },
    mygridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
      width: "95vw",
    },
  })
);

export default function RecentVendorSearch() {
  const classes = useStyles();
  const [vendors, setVendors] = useState([]);

  //   const fetchVendor = async () => {
  //     const data = await getAllVendor();
  //     setVendors(data);
  //   };

  //   useEffect(() => {
  //     fetchVendor();
  //   }, []);

  return (
    <React.Fragment>
      <h3 className="myheading">Press & Media</h3>
      <Divider />
      <br />
      <div className="reviewContainer">
        <a
          target="_blank"
          href="https://www.rni.news/%E0%A4%B0%E0%A4%BE%E0%A4%A7%E0%A4%BE%E0%A4%95%E0%A5%81%E0%A4%A3%E0%A5%8D%E0%A4%A1-%E0%A4%AE%E0%A5%87-%E0%A4%93%E0%A4%9C%E0%A4%B8-%E0%A4%B9%E0%A5%87%E0%A4%B2%E0%A5%8D%E0%A4%A5%E0%A4%95%E0%A5%87%E0%A4%AF%E0%A4%B0-%E0%A4%B8%E0%A5%87%E0%A4%82%E0%A4%9F%E0%A4%B0-%E0%A4%95%E0%A4%BE-%E0%A4%95%E0%A4%BF%E0%A4%AF%E0%A4%BE-%E0%A4%B6%E0%A5%81%E0%A4%AD%E0%A4%BE%E0%A4%B0%E0%A4%82%E0%A4%AD"
        >
          <img src="news/news6.png" width="400px" height="264px" />
        </a>
        <a
          target="_blank"
          href="https://www.neowebnews.com/53352/ojas-healthcare-center-inaugurated-in-radhakund/"
        >
          <img src="news/news1.png" width="400px" height="264px" />
        </a>
       
        <a target="_blank" href="https://tnnews24.in/44542/">
          <img src="news/news7.jpeg" width="400px" height="264px" />{" "}
              </a>
               <a
          target="_blank"
          href="https://www.livehindustan.com/uttar-pradesh/mathura/story-free-treatment-of-poor-will-be-done-in-parikrama-marg-4227766.html"
        >
          <img src="news/news2.png" width="400px" height="264px" />{" "}
        </a>
      </div>
    </React.Fragment>
  );
}
