import React from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "block",
      border: `1px solid ${theme.palette.divider}`,
      flexWrap: "wrap",
    },
    divider: {
      margin: theme.spacing(1, 0.5),
    },
    layout: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      alignContent: "space-between",
      "& .Mui-selected": {
        color: theme.palette.background.paper,
        backgroundColor: "#900000",
      },
    },
    root: {
      color: "#900000",
      backgroundColor: "#FFFFFF",
    },
    root1: {
      color: theme.palette.background.paper,
      backgroundColor: "#900000",
      "& .Mui-selected": {
        color: theme.palette.background.paper,
        backgroundColor: "#900000",
      },
    },
  })
);

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export default (props: any) => {
  const alignment = props.donorTab;

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    // setAlignment(newAlignment);
    if (newAlignment) {
      props.setDonorTab(newAlignment);
    }
  };

  const classes = useStyles();

  return (
    <Paper elevation={1} className={classes.paper}>
      <StyledToggleButtonGroup
        size="small"
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        className={classes.layout}
      >
        {/* <ToggleButton
          value="once"
          aria-label="left aligned"
          classes={{ selected: classes.root1 }}
        >
          once
        </ToggleButton>
        <ToggleButton
          value="monthly"
          aria-label="centered"
          classes={{ selected: classes.root1 }}
        >
          monthly
        </ToggleButton>
         */}
        <ToggleButton
          value="others"
          aria-label="right aligned"
          classes={{ selected: classes.root1 }}
        >
          others
        </ToggleButton>
      </StyledToggleButtonGroup>
    </Paper>
  );
};
